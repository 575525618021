import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TimeTrackingService } from './../../../services/time-tracking.service';
import { NgbActiveModal, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CNPJ } from 'cnpj';
import { TemplateRef } from '@angular/core';
import { ContractDialogComponent } from '../contract-dialog/contract-dialog.component';



@Component({
  selector: 'customer-dialog',
  templateUrl: './customer-dialog.component.html'
})
export class CustomerDialogComponent implements OnInit {

  busy: Subscription;

  constructor(private ttService: TimeTrackingService, private ngbModal: NgbModal, private modal: BsModalService, private modalRef: NgbActiveModal) { }

  public userGp;
  public activeUsers;
  public contacts;
  public statuses;
  public customer;
  public contact;

  mask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  newVersionModalRef: NgbModalRef;
  logDescModalRef: NgbModalRef;
  @Input() custId: any;



  creationUserId: number;
  statusId: number;
  contactId: number;
  totalPages: number;
  totalElements: number;

  contactHeader: string;
  cuhiDescription: string = '';
  custType: string;
  contactName: string;
  contactEmail: string;
  contactFuncion: string;
  obs: string;
  titleHeader: string;
  userName: string;
  userNameOld: string;
  name: string;
  cnpj: string;
  segment: string;
  statusName: string;
  statusNameOld: string;
  logTitle: string;


  tamanho: any;
  soma: any;
  pos: any;
  digitos: any;
  resultado: any;
  numeros: any;



  isUpdate: boolean;

  historyDate: Date;

  userSelected: any;
  cuhiCols: any;
  customerHistories: any;
  event: any;
  contractCols: any;
  contracts: any;
  fullLog: any;

  alertsDismiss: any = [];
  logDescription: any[] = [];
  customerContract: any[] = [];
  colsContact: any[] = null;

  ngOnInit() {
    sessionStorage.setItem('paraName', 'SYSTEM');
    this.userGp = { "fullName": "" };
    this.getStatus();


    this.userSelected = null;
    this.alertsDismiss = [];
    this.contactName = '';
    this.contactEmail = '';
    this.contactFuncion = '';
    this.obs = '';
    this.titleHeader = 'New Customer';
    this.name = "";
    this.cnpj = "";
    this.segment = "";
    this.creationUserId = 0;
    this.statusId = 1;
    this.custType = "";
    this.alertsDismiss = [];
    this.activeUsers = [];
    this.getActiveUsers();

    if (this.custId != null) {
      console.log(this.custId + '?page=0&size=10');
      this.isUpdate = true;
      this.getCustomer();
      this.getCustomerHistory(this.custId + '?page=0&size=10');
      //this.getContacts(); 

    }

    this.cuhiCols = [
      // { field: 'id',     header: 'History ID' },
      { field: 'date', header: 'Date' },
      { field: 'userName', header: 'User' },
      { field: 'action', header: 'Action' },
      { field: 'description', header: 'Description' },

    ];

    this.colsContact = [
      { field: 'name', header: 'Name' },
      { field: 'email', header: 'E-mail' },
      { field: 'function', header: 'Function' },
      { field: 'obs', header: 'Note' }
    ];

    this.contractCols = [
      { field: 'id', header: 'Id' },
      { field: 'name', header: 'Name' },
    ];

  }

  getCustomer() {
    this.busy = this.ttService.getCustomersById(this.custId).subscribe(
      data => {
        this.fillCustomer(data[0]);
      }
    );
  }

  getCustomerHistory(customerId) {
    this.busy = this.ttService.getCustomerHistory(customerId).subscribe(
      data => {
        this.customerHistories = data["customerLogs"];
        for (let history of this.customerHistories) {
          history["userName"] = history.author.fullName;
          this.historyDate = new Date();
          this.historyDate.setTime(history.dateCreated);
          history["date"] = this.historyDate.getFullYear() + "-" + ("0" + (this.historyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + this.historyDate.getDate()).slice(-2) + " - " + ("0" + this.historyDate.getHours()).slice(-2) + ":" + ("0" + this.historyDate.getMinutes()).slice(-2);
        }

        this.totalPages = data["pageNumber"];
        this.totalElements = data["elementNumber"];
        this.fullLog = this.customerHistories;
      });
  }

  createCustomerHistory(action) {
    this.ttService.createCustomerHistory({
      'action': action,
      'description': this.cuhiDescription
    }, this.custId, sessionStorage.getItem('userId')).subscribe(
      response => {
        this.cuhiDescription = '';
        //this.values = [];
        if (this.event) {
          this.getCustomerHistory(this.custId + '?page=' + this.event.page + '&size=10');
        } else {
          this.getCustomerHistory(this.custId + '?page=' + 0 + '&size=10');
        }
      },
      err => {
        this.ttService.checkErr(err, 'error loading customer history', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.error.errorMessage,
          timeout: 5000
        });
      },
    );
  }

  getActiveUsers() {
    this.busy = this.ttService.getActiveUsers(sessionStorage.getItem("compId")).subscribe(
      data => {
        this.activeUsers = data;
        if (this.userSelected) {
          if (!this.activeUsers.find(user => user.id == this.userSelected)) {
            this.ttService.getUserByIdID(this.userSelected).subscribe(user => {
              this.activeUsers.push(Object.assign({}, user));
            })
          }
        }

      },
      err => {
        console.log(err)
        this.ttService.checkErr(err, 'error loading users', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.error.errorMessage,
          timeout: 5000
        });
      });
  }


  getStatus() {
    this.ttService.getStatusByParameters(sessionStorage.getItem('compId'), sessionStorage.getItem('paraName')).subscribe(
      data => { this.statuses = data },
      err => {
        this.ttService.checkErr(err, 'error loading status', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.error.errorMessage,
          timeout: 5000
        });
      },
      () => console.log('done loading status')
    );
  }

  fillCustomer(customer) {
    this.customer = customer;
    this.titleHeader = 'View Customer';

    this.userSelected = customer.managerUserId;

    this.name = customer.name;
    this.cnpj = customer.cnpj;
    this.segment = customer.segment;
    this.creationUserId = customer.creationUserId;
    this.statusId = customer.statusId;
    this.custType = customer.custType;

  }

  saveCustomer() {
    if (!this.custId) {
      this.createCustomer();
    } else {
      this.updateCustomer();
    }
  }

  createCustomer() {
    //const valid = CNPJ.validarCNPJ(this.cnpj);
    const valid = this.validarCNPJ(this.cnpj);
    let alertMsg = {
      type: 'danger',
      msg: `CNPJ Invalid`,
      timeout: 5000
    };
    if (this.cnpj == "00.000.000/0000-00") {
      console.log(this.cnpj)
    } else {
      if (!valid) {
        this.alertsDismiss = [];
        this.alertsDismiss.push(alertMsg);
        return;
      }
    }
    let cnpj = this.cnpj.replace(/[//\.-]/g, "");
    this.busy = this.ttService.createCustomer({
      "name": this.name,
      "cnpj": cnpj,
      "segment": this.segment,
      "creationUserId": sessionStorage.getItem('userId'),
      "updateUserId": sessionStorage.getItem('userId'),
      "statusId": this.statusId,
      "companyId": sessionStorage.getItem('compId'),
      "custType": this.custType,
      "managerUserId": this.userSelected
    }).subscribe(
      response => {
        console.log("")
        this.custId = response;
        this.cuhiDescription = 'Customer ' + this.custId + ' is saved';
        this.createCustomerHistory("INSERT");
        if (this.event) {
          this.getCustomerHistory(this.custId + '?page=' + this.event.page + '&size=10');
        } else {
          this.getCustomerHistory(this.custId + '?page=' + 0 + '&size=10');
        }
        alert('Customer is saved');
        this.close();
      },
      err => {
        this.ttService.checkErr(err, 'error loading customer', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.error.errorMessage,
          timeout: 5000
        });
      }
    );
  }

  updateCustomer() {
    //const valid = CNPJ.validate(this.cnpj);
    const valid = this.validarCNPJ(this.cnpj);
    if (this.cnpj == "00.000.000/0000-00" || this.cnpj == "00000000000000") {
      console.log(this.cnpj)
    } else {
      if (!valid) {
        this.alertsDismiss.push({
          type: 'danger',
          msg: `CNPJ Invalid`,
          timeout: 5000
        });
        return;
      }
    }
    let cnpj = this.cnpj.replace(/[//\.-]/g, "");
    this.ttService.updateCustomer({
      "name": this.name,
      "cnpj": cnpj,
      "segment": this.segment,
      "statusId": this.statusId,
      "updateUserId": sessionStorage.getItem('userId'),
      "custType": this.custType,
      "managerUserId": this.userSelected
    }, this.custId).subscribe(
      response => {
        let desc: string = '';
        this.cuhiDescription = '';

        if (this.name != this.customer.name) {
          desc = "'Name' changed from [" + this.customer.name + "] to [" + this.name + "]";
          if (this.cuhiDescription != '') {
            this.cuhiDescription += ", "
          }
          this.cuhiDescription += desc;
        }

        let cnpj = this.cnpj.replace(/[//\.-]/g, "");
        if (cnpj != this.customer.cnpj) {
          desc = "'CNPJ' changed from [" + this.customer.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") + "] to [" + this.cnpj + "]";
          if (this.cuhiDescription != '') {
            this.cuhiDescription += ", "
          }
          this.cuhiDescription += desc;
        }

        if (this.segment != this.customer.segment) {
          desc = "'Segment' changed from [" + this.customer.segment + "] to [" + this.segment + "]";
          if (this.cuhiDescription != '') {
            this.cuhiDescription += ", "
          }
          this.cuhiDescription += desc;
        }

        if (this.statusId != this.customer.statusId) {
          for (let status of this.statuses) {
            if (status["id"] == this.statusId) {
              this.statusName = status["name"];
            }
            if (status["id"] == this.customer.statusId) {
              this.statusNameOld = status["name"];
            }
          }
          desc = "'Status' changed from [" + this.statusNameOld + "] to [" + this.statusName + "]";
          if (this.cuhiDescription != '') {
            this.cuhiDescription += ", "
          }
          this.cuhiDescription += desc;
        }

        let typeOld = this.customer.custType;
        let type = this.custType;
        if (typeOld == "E") {
          typeOld = "External";
        } else {
          typeOld = "Internal";
        }
        if (type == "E") {
          type = "External";
        } else {
          type = "Internal";
        }
        if (type != typeOld) {
          desc = "'Type' changed from [" + typeOld + "] to [" + type + "]";
          if (this.cuhiDescription != '') {
            this.cuhiDescription += ", "
          }
          this.cuhiDescription += desc;
        }

        if (this.userSelected != this.customer.managerUserId) {
          for (let user of this.activeUsers) {
            if (user["id"] == this.userSelected) {
              this.userName = user["fullName"];
            }
            if (user["id"] == this.customer.managerUserId) {
              this.userNameOld = user["fullName"];
            }
          }
          desc = "'GP' changed from [" + this.userNameOld + "] to [" + this.userName + "]";
          if (this.cuhiDescription != '') {
            this.cuhiDescription += ", "
          }
          this.cuhiDescription += desc;
        }

        if (this.event) {
          this.getCustomerHistory(this.custId + '?page=' + this.event.page + '&size=10');
        } else {
          this.getCustomerHistory(this.custId + '?page=' + 0 + '&size=10');
        }

        alert('Customer ' + this.custId + ' is saved!')
        if (this.cuhiDescription != "") {
          this.createCustomerHistory('UPDATE');
        }
        this.getCustomer();

      },
      err => {
        this.ttService.checkErr(err, 'error update customer', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.error.errorMessage,
          timeout: 5000
        });

      });
  }

  open(param, template: TemplateRef<any>, options) {
    this.newVersionModalRef = this.ngbModal.open(template, options);
    console.log(param)
    if (param) {
      if (param == 'view') {
        this.getContacts();
      } else {
        console.log(param)
        this.contact = param;
        this.contactName = param.name;
        this.contactEmail = param.email;
        this.contactFuncion = param.function;
        this.obs = param.obs;
        this.contactId = param.id;
        this.contactHeader = 'View Contact #' + this.contactId;
      }
    } else {
      this.contactName = '';
      this.contactEmail = '';
      this.contactFuncion = '';
      this.obs = '';
      this.contactId = null;
      this.contactHeader = 'New Contact';
    }
  }

  openHistory(row, template: TemplateRef<any>) {
    this.logTitle = row.id + " - " + row.action;
    this.populationViewLog(row);
    this.logDescModalRef = this.ngbModal.open(template);
  }

  openContract(action, template: TemplateRef<any>) {
    if (action == "Add") {
      let modalDialog = this.ngbModal.open(ContractDialogComponent, { size: 'lg' });
      modalDialog.componentInstance.custId = this.custId;
    }
    if (action == "View") {
      this.getContract();
      this.newVersionModalRef = this.ngbModal.open(template);
    }
  }

  getContract() {
    this.busy = this.ttService.getContracts(sessionStorage.getItem("compId")).subscribe(
      data => {
        this.customerContract = [];
        this.contracts = data['contracts'];
        console.log(this.contracts);
        for (let contract of this.contracts) {
          if (contract.customer) {
            if (contract.customer.custId == this.custId) {
              this.customerContract.push(contract);
            }
          }
        }
      },
      err => {
        this.ttService.checkErr(err, 'error get Contract', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.errorerrorMessage,
          timeout: 5000
        });
      },
      () => console.log("Done loading Contracts")
    )
  }

  getContacts() {
    this.busy = this.ttService.getContacts(this.custId).subscribe(
      data => {
        this.contacts = data;
      },
      err => {
        alert('Contacts not found');
      }
    );
  }

  saveContact() {
    if (this.contactId == null) {
      this.createContact();
    } else {
      this.updateContact(this.contactId);
    }
  }

  createContact() {
    this.busy = this.ttService.createContact({
      "name": this.contactName,
      "email": this.contactEmail,
      "function": this.contactFuncion,
      "obs": this.obs,
      "customerId": this.custId,
    }).subscribe(
      response => {
        alert('Contact is saved.')
        this.cuhiDescription = 'Contact [' + this.contactName + '] is saved';
        this.createCustomerHistory("CONTACT");
        if (this.event) {
          this.getCustomerHistory(this.custId + '?page=' + this.event.page + '&size=10');
        } else {
          this.getCustomerHistory(this.custId + '?page=' + 0 + '&size=10');
        }
        this.closeContact();
      },
      err => {
        this.ttService.checkErr(err, 'error create contact', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.error.errorMessage,
          timeout: 5000
        });

      });
  }

  updateContact(contactId) {
    this.busy = this.ttService.updateContact({
      "name": this.contactName,
      "email": this.contactEmail,
      "function": this.contactFuncion,
      "obs": this.obs,
      "customerId": this.custId,
    }, contactId).subscribe(
      response => {
        let desc: string = '';
        this.cuhiDescription = 'Contact ' + contactId + ' changed:';

        if (this.contactName != this.contact.name) {
          desc = "'Name' changed from [" + this.contact.name + "] to [" + this.contactName + "]";
          if (this.cuhiDescription != '') {
            this.cuhiDescription += ", "
          }
          this.cuhiDescription += desc;
        }

        if (this.contactEmail != this.contact.email) {
          desc = "'Email' changed from [" + this.contact.email + "] to [" + this.contactEmail + "]";
          if (this.cuhiDescription != '') {
            this.cuhiDescription += ", "
          }
          this.cuhiDescription += desc;
        }

        if (this.contactFuncion != this.contact.function) {
          desc = "'Function' changed from [" + this.contact.function + "] to [" + this.contactFuncion + "]";
          if (this.cuhiDescription != '') {
            this.cuhiDescription += ", "
          }
          this.cuhiDescription += desc;
        }

        if (this.obs != this.contact.obs) {
          desc = "'Note' changed from [" + this.contact.obs + "] to [" + this.obs + "]";
          if (this.cuhiDescription != '') {
            this.cuhiDescription += ", "
          }
          this.cuhiDescription += desc;
        }


        alert('Contact is saved.');
        this.closeContact();
        console.log(this.cuhiDescription != "");

        if (this.cuhiDescription != 'Contact ' + contactId + ' changed:') {
          this.createCustomerHistory('CONTACT');
        }
      },
      err => {
        this.ttService.checkErr(err, 'error update contact', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.error.errorMessage,
          timeout: 5000
        });
      });
  }

  saveComment() {
    this.createCustomerHistory('COMMENT');
    this.newVersionModalRef.close('')
  }

  openComment(template: TemplateRef<any>) {
    this.newVersionModalRef = this.ngbModal.open(template);
  }

  closeContact() {
    this.newVersionModalRef.close();
    if (this.contactId) {
      this.getContacts();
    }
  }

  close() {
    this.modalRef.close('');
  }

  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.event = event;
    this.getCustomerHistory(this.custId + '?page=' + event.page + '&size=10');
  }

  // deleteContact(contact){
  //   if (confirm("Are you sure you want to delete contact " + contact.id + "?")) {
  //   this.ttService.deleteContact(contact).subscribe(
  //     response => {
  //       this.contacts = [];
  //       this.getContacts(this.id);
  //     },
  //     err => {
  //       this.ttService.checkErr(err,'error delete contact', this.modalRef);
  //       this.alertsDismiss.push({
  //         type: 'danger',
  //         msg: err.error.errorMessage,
  //         timeout: 5000
  //       });

  //     }
  //   )
  //   }
  // }

  populationViewLog(row) {
    this.logDescription = new Array();
    let positions = new Array();
    if (row.action == "UPDATE") {
      positions.push(parseInt(row.description.indexOf("'Name'"), 10));
      positions.push(parseInt(row.description.indexOf("'CNPJ'"), 10));
      positions.push(parseInt(row.description.indexOf("'Segment'"), 10));
      positions.push(parseInt(row.description.indexOf("'Status'"), 10));
      positions.push(parseInt(row.description.indexOf("'Type'"), 10));
      positions.push(parseInt(row.description.indexOf("'GP'"), 10));
      positions.push(row.description.length);
      positions.sort(function (a, b) { return a - b; });

      for (let position in positions) {
        let aux = parseInt(position, 10);
        if (positions[aux] != -1 && aux != positions.length - 1) {
          // The last vector it is not necessary to remove the commas
          if (aux == positions.length - 2) {
            // Business rule to show a maximum of 300 characters
            if ((positions[aux + 1] - positions[aux]) > 300) {
              this.logDescription.push(row.description.substring(positions[aux], positions[aux] + 300) + '...');
            } else {
              this.logDescription.push(row.description.substring(positions[aux], positions[aux + 1]));
            }
            // Remove the commas
          } else {
            // Business rule to show a maximum of 300 characters
            if ((positions[aux + 1] - positions[aux]) > 300) {
              this.logDescription.push(row.description.substring(positions[aux], positions[aux] + 300) + '...');
            } else {
              this.logDescription.push(row.description.substring(positions[aux], positions[aux + 1] - 2));
            }
          }
        }
      }
    } else {
      this.logDescription.push(row.description);
    }
  }
  validarCNPJ(cnpj) {



    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
      return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;


    // Valida DVs
    this.tamanho = cnpj.length - 2
    this.numeros = cnpj.substring(0, this.tamanho);
    this.digitos = cnpj.substring(this.tamanho);
    this.soma = 0;
    this.pos = this.tamanho - 7;
    for (let i = this.tamanho; i >= 1; i--) {
      this.soma += this.numeros.charAt(this.tamanho - i) * this.pos--;
      if (this.pos < 2)
        this.pos = 9;
    }
    this.resultado = this.soma % 11 < 2 ? 0 : 11 - this.soma % 11;
    if (this.resultado != this.digitos.charAt(0))
      return false;

    this.tamanho = this.tamanho + 1;
    this.numeros = cnpj.substring(0, this.tamanho);
    this.soma = 0;
    this.pos = this.tamanho - 7;
    for (let i = this.tamanho; i >= 1; i--) {
      this.soma += this.numeros.charAt(this.tamanho - i) * this.pos--;
      if (this.pos < 2)
        this.pos = 9;
    }
    this.resultado = this.soma % 11 < 2 ? 0 : 11 - this.soma % 11;
    if (this.resultado != this.digitos.charAt(1))
      return false;

    return true;

  }
}