import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeTrackingService } from '../../../services/time-tracking.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-billing-dialog',
  templateUrl: './billing-dialog.component.html',
  styleUrls: ['./billing-dialog.component.scss']
})
export class BillingDialogComponent implements OnInit {
  busy: Subscription;

  constructor(private modalClose: NgbActiveModal, private ttService: TimeTrackingService) { }

  @Input() projectId;
  @Input() id;

  billingTypes;
  dateError;

  costType;
  invoice;
  description;
  dateCreated;
  value;

  title: string = 'Add Billing';

  ngOnInit() {
    this.description = '';
    this.getBillingType();
    if (this.id) {
      this.title = 'Edit Billing';
      this.getById();
    }
  }

  close() {
    this.modalClose.close(BillingDialogComponent);
  }
  save() {
    if (this.id) {
      this.update();
    } else {
      this.create();
    }
  }

  update() {
    if (!this.validateDates(this.dateCreated)) {
      alert('Project Start Date: ' + this.dateError);
    } else {
      let dateCreated;
      if (this.dateCreated && this.dateCreated.day && this.dateCreated.month) {
        dateCreated = new Date();
        dateCreated.setFullYear(this.dateCreated.year);
        dateCreated.setMonth(this.dateCreated.month - 1);
        dateCreated.setDate(this.dateCreated.day);
      }

      this.busy = this.ttService.updateCost(
        {
          'lastUpdated': dateCreated,
          'project': this.projectId,
          'costType': this.costType,
          'invoice': this.invoice,
          'description': this.description,
          'value': this.value
        }, this.id
      ).subscribe(
        () => {
          alert('Billing updated');
          this.close();
        }
      );
    }
  }

  create() {
    if (!this.validateDates(this.dateCreated)) {
      alert('Project Start Date: ' + this.dateError);
    }
    let dateCreated;
    if (this.dateCreated && this.dateCreated.day && this.dateCreated.month) {
      dateCreated = new Date();
      dateCreated.setFullYear(this.dateCreated.year);
      dateCreated.setMonth(this.dateCreated.month - 1);
      dateCreated.setDate(this.dateCreated.day);
    }

    this.busy = this.ttService.createCost(
      {
        'lastUpdated': dateCreated,
        'project': this.projectId,
        'costType': this.costType,
        'invoice': this.invoice,
        'description': this.description,
        'value': this.value
      }
    ).subscribe(
      () => {
        alert('Billing created');
        this.close();
      });
  }

  delete() {
    if (confirm('Are you sure you want to delete ' + this.id + '?')) {
      this.ttService.deleteCost(this.id).subscribe(() => {
        alert('Cost deletado.');
        this.close();
      });
    }
  }

  getBillingType() {
    this.ttService.getBillingType().subscribe(data => {
      this.billingTypes = data;
    });
  }

  getById() {
    this.ttService.getCostById(this.id).subscribe(data => {
      this.costType = data['costType'];
      this.invoice = data['invoice'];
      this.description = data['description'];
      this.value = data['value'];

      this.dateCreated = new Date();
      this.dateCreated.setTime(data['lastUpdated']);
      this.dateCreated = {
        // tslint:disable-next-line: radix
        year: parseInt(this.dateCreated.getFullYear()),
        // tslint:disable-next-line: radix
        month: parseInt(('0' + (this.dateCreated.getMonth() + 1)).slice(-2)),
        // tslint:disable-next-line: radix
        day: parseInt(('0' + this.dateCreated.getDate()).slice(-2))
      };
    });
  }

  validateDates(date) {
    const regex = new RegExp('^([0-9]{4}-[0-9]{2}-[0-9]{2})$', 'g');
    let isValid = true;
    const invalidFormat = 'Invalid date format.';
    const invalidRange = 'Invalid date range.';
    this.dateError = null;
    if (typeof date === 'string') {
      if (regex.test(date)) {
        isValid = false;
        this.dateError = invalidRange;
      } else {
        isValid = false;
        this.dateError = invalidFormat;
      }
    } else if (date.year < 1969 || date.year > 2099) {
      // tslint:disable-next-line: triple-equals
      if (date.year.toString().length == 4) {
        this.dateError = invalidRange;
      } else {
        this.dateError = invalidFormat;
      }
      isValid = false;
    }
    return isValid;
  }

}
