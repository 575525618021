    import { Component, OnInit } from '@angular/core';
    import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
    import { Subscription } from 'rxjs';
    import { TimeTrackingService } from './../../../services/time-tracking.service';
    import { startOfDay, endOfDay } from 'date-fns';
    import { IfObservable } from 'rxjs/observable/IfObservable';
    import { TTBody } from 'primeng/treetable';
    import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

    @Component({
    selector: 'app-timesheet-dialog',
    templateUrl: './timesheet-dialog.component.html'
    })
    export class TimeSheetDialogComponent implements OnInit {
    
    public systems;

    titleHeader = 'New TimeSheet';
    busy: Subscription;
    
    disableEdit;
    disableProject: boolean;
    disableMilestone: boolean;
    disableTask: boolean;
    disableSystem: boolean;
    disableTicket: boolean;
    disableType: boolean;
    disableAll: boolean;

    id;
    timeStart1: any;
    timeEnd1: any;
    timeStart2: any;
    timeEnd2: any;
    modelDate: any;
    selectedTask: any;
    selectedClient: any;
    selectedProject: any;
    selectedMilestone: any;
    selectedSystem: any;
    selectedTicket: any;
    selectedClientText;
    selectedTaskText;
    selectedProjectText;

    reason: string;
    type: string;
    description: any;
    comments: any;
    ticketDescription: any;
    ownerUserId: any;
    typeSelected: any;
    alertsDismiss: any;
    selectedFullTask: Object;
    clients;
    projects;
    status;
    milestones;
    tickets;
    tt;
    enableSecondTT = true;
    tempFinishDate;
    tempFinishDateTr;
    tasks: any[];
    lockTask = false;
    buttonAction = false;
    events = [];

    constructor(private ttService: TimeTrackingService, 
        private modalRef: NgbActiveModal) { 
        this.alertsDismiss = [];
        }

    ngOnInit() {
        this.getClients();
        this.getStatus();
        this.disableProject = true;
        this.disableMilestone = true;
        this.disableTask = true;
        this.disableSystem = true;
        this.disableTicket = true;
        this.disableAll = false;
        this.tasks = [];

        if(!this.type){
            this.type = '';
        }
        // preenchendo as combos
        if(this.selectedClient) {
            if(this.type == 'Project'){
                this.getProjects(this.selectedClient);
            }else{
                //Carrega todos os campos do ticket
                this.getSystemsByCustomer(this.selectedClient, -1);
                this.onChangeSystem();
                this.getTicket(this.selectedTicket);
            }
        }

        if(this.selectedProject) {
            this.getTasks(this.selectedProject, +sessionStorage.getItem('compId'));
        if(this.selectedMilestone)
            this.getMilestoneById(this.selectedMilestone);
        }

        if(this.selectedTask) {
            this.getTaskById(+this.selectedTask);
        }

        if(this.tt) {
            this.disableType = true;
            this.enableSecondTT = false;
            this.buttonAction = true;
            if(this.type == 'Project'){

                this.titleHeader = 'Edit Timesheet #' + this.tt.id;
                this.id = this.tt.id;

                this.selectedClient = this.tt.customerId;
                this.getProjects(this.selectedClient);

                this.selectedProject = this.tt.projectId;
                this.selectedTask = this.tt.taskId;
                this.selectedClientText = this.tt.customerName;
                this.selectedProjectText = this.tt.projectName;
                this.selectedTaskText = this.tt.taskName;

                this.getMilestone(this.selectedProject);
                if(this.tt.mileId){
                    this.selectedMilestone = this.tt.mileId;
                }

                let timehour: number = parseInt(this.tt.hourDecimal);
                this.typeSelected = this.tt.typeAmount;
                if(this.tt.typeAmount == 'Duration') {
                    let timestart = new Date(this.tt.appointmentStartDateS);
                    this.timeStart1 = {hour: timestart.getHours(), minute: timestart.getMinutes()};  
                    let timeend = new Date(this.tt.appointmentFinishDateS);
                    this.timeEnd1 = {hour: timeend.getHours(), minute: timeend.getMinutes()};
                }

                else {
                    this.timeStart1 = {hour: 0, minute: 0};
                    this.timeEnd1 = {hour: 0, minute: 0};
                }
                
                this.description = this.tt.description;
                this.ownerUserId = this.tt.ownerUserId;

                if(this.tt.appointmentDate != null) {
                    let appointmentDate = new Date(this.tt.appointmentDate).toISOString();
                    this.tempFinishDate = appointmentDate.split("T")
                    this.tempFinishDateTr = this.tempFinishDate[0].split("-");
                    this.modelDate = {year: parseInt(this.tempFinishDateTr[0]), month: parseInt(this.tempFinishDateTr[1]), day: parseInt(this.tempFinishDateTr[2])};
                }
                this.disableProject = false;
                this.disableMilestone = false;
                this.disableTask = false;
                this.getTimeTrackingSpecifiedTasks(this.tt);
            }else{

                this.id = this.tt.id;

                if(this.tt.appointmentDate != null) {
                    let appointmentDate = new Date(this.tt.appointmentDate).toISOString();
                    this.tempFinishDate = appointmentDate.split("T")
                    this.tempFinishDateTr = this.tempFinishDate[0].split("-");
                    this.modelDate = {year: parseInt(this.tempFinishDateTr[0]), month: parseInt(this.tempFinishDateTr[1]), day: parseInt(this.tempFinishDateTr[2])};
                }

                // para fazer a conversão eu faço o split na string
                let minutes = this.getMinutes(this.tt.timeStart.split(" "));
                this.timeStart1 = {hour: parseInt(minutes[0], 10), minute: parseInt(minutes[1], 10)};

                let minutesEnd = this.getMinutes(this.tt.timeEnd.split(" "));
                this.timeEnd1 = {hour: parseInt(minutesEnd[0], 10), minute: parseInt(minutesEnd[1], 10)};

                this.selectedClient = this.tt.customer.custId;
                this.selectedSystem = this.tt.system.id;
                this.selectedTicket = this.tt.ticket.id;
                this.comments = this.tt.comments;
                this.disableSystem = false;
                this.disableTicket = false;
            }
        }
    }

    getMinutes(hoursMinutes){
        return hoursMinutes[1].split(":");

    }

    onChangeClient(event) {
        let selectElement = event.target;
        var optionIndex = selectElement.selectedIndex;
        var optionText = selectElement.options[optionIndex];
        this.selectedClientText = optionText.label;

        this.selectedClient = event.target.value;
        
        this.projects = [];
        this.selectedTask = '';
        this.selectedTaskText = '';
        this.selectedProject = '';
        this.selectedProjectText = '';

        this.selectedMilestone = '';

        if(this.type == 'Project'){
            this.getProjects(event.target.value);
        }else{
            this.getSystemsByCustomer(event.target.value, -1);
            this.selectedSystem = '';
            this.selectedTicket = '';
        }
        this.selectedTask = '';
        this.selectedTaskText = '';
        this.selectedProject = '';
        this.selectedProjectText = '';
        this.disableProject = false;
        this.disableSystem = false;
    }

    onChangeProject(event) {
        let selectElement = event.target;
        var optionIndex = selectElement.selectedIndex;
        var optionText = selectElement.options[optionIndex];
        this.selectedProjectText = optionText.label;

        this.selectedProject = event.target.value;

        this.milestones = [];
        this.tasks = [];
        this.selectedMilestone = '';
        this.disableMilestone = false;
        this.disableTask = false;
        if(this.selectedProject || this.selectedProject != '') {
        this.getMilestone(this.selectedProject);
        this.getTasks(this.selectedProject, +sessionStorage.getItem('compId'));
        }
    }

    onChangeMilestone(event) {
        let selectElement = event.target;
        var optionIndex = selectElement.selectedIndex;
        var optionText = selectElement.options[optionIndex];
        this.selectedProjectText = optionText.label;
        this.selectedMilestone = event.target.value;

        this.tasks = [];
        
        this.selectedTask = '';

        if(this.selectedMilestone) {
            this.getTasksByPhase(this.selectedMilestone, +sessionStorage.getItem('compId'));
        }else{
            this.getTasks(this.selectedProject, +sessionStorage.getItem('compId'));
        }
    }

    onChangeTask(event) {
        let selectElement = event.target;
        var optionIndex = selectElement.selectedIndex;
        var optionText = selectElement.options[optionIndex];
        this.selectedTaskText = optionText.label;

        let task = this.tasks.filter(task => task.id == event.target.value);
        let filteredPhases = this.milestones.filter(phase => phase.id == task[0].phase.id);
        console.log('filteredPhases: ', filteredPhases);
        this.selectedMilestone = filteredPhases.length > 0 ? filteredPhases[0].id : this.selectedMilestone;

        this.selectedTask = event.target.value;
    }

    onChangeSystem(){
        this.busy = this.ttService.getTicketsBySystem(this.selectedSystem).subscribe(
            data => {
                this.tickets = data['tickets'];
                if(!this.selectedTicket){
                    this.disableTicket = false;
                }
            },
            err => {
                this.ttService.checkErr(err,'error loading tickets', this.modalRef);
                this.alertsDismiss.push({
                type: 'danger',
                msg: err.error.errorMessage,
                timeout: 5000
                });
                
            },
            () => console.log('done loading tickets')
            );
    }

    onChangeTicket(){
        for(let ticket of this.tickets){
           if(ticket.id == this.selectedTicket){
               this.ticketDescription = ticket.description;
           }
        }
    }

    onChangeType(){
        if(this.type == 'Project'){
            this.selectedClient = '';
            this.selectedProject = '';
            this.selectedMilestone = '';
            this.selectedTask = '';
            this.disableProject = true;
            this.disableMilestone = true;
            this.disableTask = true;

        }else{
            this.selectedClient = '';
            this.selectedSystem = '';
            this.selectedTicket = '';
            this.ticketDescription = '';
            this.disableSystem = true;
            this.disableTicket = true;
        }
    }

    getClients() {
        this.ttService.getCustomersAlphabetical(sessionStorage.getItem('compId')).subscribe(
        data => { this.clients = data;
        },
        err => {
            this.ttService.checkErr(err,'error loading clients', this.modalRef);
            this.alertsDismiss.push({
            type: 'danger',
            msg: err.error.errorMessage,
            timeout: 5000
            });
            
        },
        () => console.log('done loading clients')
        );
    }

    getTicket(ticketId) {
        this.busy = this.ttService.getTicket(ticketId).subscribe(
          data => { 
            this.ticketDescription = data['description'];
          });
      }

    getProjects(customerId) {
        this.ttService.getProjectsForCompIdCustId(sessionStorage.getItem('compId'), customerId).subscribe(
        data => { 
            this.projects = (<any[]>data).filter(p => p.statusName !== 'Closed');
        },
        err => {
            this.ttService.checkErr(err,'error loading projects', this.modalRef);
            this.alertsDismiss.push({
            type: 'danger',
            msg: err.error.errorMessage,
            timeout: 5000
            });
            
        },
        () => console.log('done loading projects')
        );
    }

    getStatus() {
        this.busy = this.ttService.getStatusByParameters(+sessionStorage.getItem('compId'), 'TASK_OPENED').subscribe(
            data => {
                let response:any = data;
                this.status = response.filter(status => status.name == 'In Progress')[0].id;
            },
            err => {
                this.ttService.checkErr(err,'error loading tasks', this.modalRef);
                this.alertsDismiss.push({
                type: 'danger',
                msg: err.error.errorMessage,
                timeout: 5000
                });
            },
            () => console.log('done loading status')
        );
    }

    getTaskById(taskId: number) {
        this.ttService.getTaskById(taskId).subscribe(
            data => { this.tasks.push(data); }
        );
    }

    getTasksByPhase(phaseId: number, companyId: number){
        let filter = {
            'phase': [{'id': phaseId}],
            'status': [{'id': this.status}],
            'company': {'id': companyId}
        };
        this.busy = this.ttService.getTasksBySpecification(filter, 0, 2000).subscribe(
            data => { 
                this.tasks = data['tasks'];
            },
            err => {
                this.ttService.checkErr(err,'error loading tasks', this.modalRef);
                this.alertsDismiss.push({
                type: 'danger',
                msg: err.error.errorMessage + ' Please contact your manager for more information.',
                timeout: 5000
                });
            },
            () => console.log('done loading tasks')
        );
    }

    getMilestone(projectId){
        this.ttService.getPhasesByProject(projectId).subscribe(
        data => { 
            this.milestones = data["phases"];
        },
        err => {
            this.ttService.checkErr(err,'error loading phases', this.modalRef);
        },
        () => console.log('done loading phases')
        );
    }

    getMilestoneById(milestoneId) {
        this.busy = this.ttService.getPhaseById(milestoneId).subscribe(
        data => {
            this.milestones = [data];
        },
        err => {
            this.ttService.checkErr(err, 'error loading phase', this.modalRef);
        },
        () => console.log('done loading phase')
        );
    }

    getTasks(projectId: number, companyId: number) { 
        let filter = {
            'project': [{'id': projectId}],
            'status' : [{'id': this.status}],
            'company': {'id': companyId}
        };
        this.busy = this.ttService.getTasksBySpecification(filter, 0, 2000).subscribe(
        data => { 
            this.tasks = data['tasks'];
            for(let task of this.tasks){
            if(task.taskId == this.selectedTask){
                if(task.statusName == 'Closed'||task.statusName == 'Canceled' || task.statusName == 'On Hold' || task.statusName == 'Planned'){
                    this.disableAll = true;
                }
            }
            }  
        },
        err => {
            if( !this.selectedTask ) {
                this.ttService.checkErr(err,'error loading tasks', this.modalRef);
                this.alertsDismiss.push({
                type: 'danger',
                msg: err.error.errorMessage  + ' Please contact your manager for more information.',
                timeout: 5000
                });
            }            
        },
        () => console.log('done loading tasks')
        );
    }

    getTimeTrackingSpecifiedTasks(timeTracking) {
        let phase = timeTracking.mileId == '' ? null : [{'id': timeTracking.mileId}];
        let project = timeTracking.projectId == '' ? null : [{'id': timeTracking.projectId}];
        let filter = {
            'customer': [{'custId': timeTracking.customerId}],
            'project': project,
            'phase': phase,
            'company': {'id': timeTracking.companyId}
        };
        this.busy = this.ttService.getTasksBySpecification(filter, 0, 2000).subscribe(
            data => {
                let response: any = data['tasks'];
                this.tasks = response.filter(task => task.status.name == "In Progress" || task.id == timeTracking.taskId);
                this.disableAll = this.tasks.filter(task => task.id == timeTracking.taskId)[0].status.name != 'In Progress' ? true : false;
            }
        );
    }

    getSystemsByCustomer(customer, systemId) {
        this.busy = this.ttService.getSystemsByCustomerAndSystemId(customer,systemId).subscribe(
          data => {
            this.systems = data["systems"];       
          }
        );
    }

    close() { 
        this.modalRef.close(this.reason)
    }

    save() {
        if(this.timeStart1==undefined || this.timeEnd1 == undefined || this.timeStart1 == null || this.timeEnd1 == null){
        this.alertsDismiss.push({
            type: 'danger',
            msg: 'Invalid Dates!',
            timeout: 5000
        });
        }else{
        if(this.id) {
            if(this.type == 'Project'){
                this.updateTimeTracking();
            }else{
                this.updateTimeTrackingTicket();
            }
        } else {
            if(this.type == 'Project'){
                this.createTimeTracking();
            }else{
                this.createTimeTrackingTicket();
            }
        }
    }
    }

    updateTimeTracking() {
        var dateStart = null;
        let totalMinutesStart = (this.timeStart1.hour * 60) + this.timeStart1.minute;
        let totalMinutesEnd = (this.timeEnd1.hour * 60) + this.timeEnd1.minute;

        if (totalMinutesEnd > totalMinutesStart) {
        if (this.modelDate && this.modelDate.day && this.modelDate.month) {
            dateStart = new Date(this.modelDate.year, this.modelDate.month-1, this.modelDate.day, 0, 0, 0);
        }

        this.busy = this.ttService.updateTimeTracking({
            "taskId":            this.selectedTask,
            "customerId":        this.selectedClient,
            "projectId":         this.selectedProject, 
            "mileId":             this.selectedMilestone,     
            "strAmountHourStart": this.timeStart1.hour + ':' + this.timeStart1.minute,
            "strAmountHourEnd":   this.timeEnd1.hour + ':' + this.timeEnd1.minute,
            "appointmentDate":   dateStart,
            "description":       this.description,
            "ownerUserId":       this.ownerUserId,
            "userId":            sessionStorage.getItem('userId'),
            "companyId":         sessionStorage.getItem('compId'),
            "updateUserId":      sessionStorage.getItem('userId'),
            "typeAmount" :       this.typeSelected      
            }, this.id
        )
        .subscribe(
            data => {
            alert("Timesheet saved.");
            this.reason = 'progress update';
            this.close();
            },
            err => {
            this.ttService.checkErr(err,'error update time tracking', this.modalRef);
            this.alertsDismiss.push({
                type: 'danger',
                msg: err.error.errorMessage,
                timeout: 5000
            });
            }
        );
        }
        else {
        this.alertsDismiss.push({
            type: 'danger',
            msg: 'Appointment error',
            timeout: 5000
        });
        }
    }

    updateTimeTrackingTicket(){
        var dateStart = null;
        let totalMinutesStart = (this.timeStart1.hour * 60) + this.timeStart1.minute;
        let totalMinutesEnd = (this.timeEnd1.hour * 60) + this.timeEnd1.minute;

        if (totalMinutesEnd > totalMinutesStart) {
        if (this.modelDate && this.modelDate.day && this.modelDate.month) {
            dateStart = new Date(this.modelDate.year, this.modelDate.month-1, this.modelDate.day, 0, 0, 0);
        }

        this.busy = this.ttService.updateTimeTrackingTicket({
            "ticket":           {'id' : this.selectedTicket},
            "customer":         {'custId' : this.selectedClient},
            "system":           {'id' : this.selectedSystem},
            "description":        this.ticketDescription,
            "comments":           this.comments,
            "timeStart":          this.timeStart1.hour + ':' + this.timeStart1.minute,
            "timeEnd":            this.timeEnd1.hour + ':' + this.timeEnd1.minute,
            "appointmentDate":    dateStart,
            "userId":             sessionStorage.getItem('userId'),
            "companyId":          sessionStorage.getItem('compId'),
            "creationUserId":     sessionStorage.getItem('userId')
        }, this.id
        )
        .subscribe(
            data => {
            alert("Timesheet saved.");
            this.reason = 'progress update';
            this.close();
            },
            err => {
            this.ttService.checkErr(err,'error update time tracking', this.modalRef);
            this.alertsDismiss.push({
                type: 'danger',
                msg: err.error.errorMessage,
                timeout: 5000
            });
            }
        );
        }
        else {
        this.alertsDismiss.push({
            type: 'danger',
            msg: 'Appointment error',
            timeout: 5000
        });
        }
    }

    getTimeSheet() {
        // if(sessionStorage.getItem('userProfile') == 'ANALYST') {
        this.busy = this.ttService.getTimeTrackingUser(
            sessionStorage.getItem('compId'), 
            sessionStorage.getItem('userId')).subscribe(
            data => { 
            this.events = [];
            this.tt = data;
            this.tt.forEach(ts => {
                let event = {
                title: ts.taskName + '  - Total Hours: (' + ts.hourDecimal + ')',
                start: startOfDay(ts.appointmentDate),
                end: endOfDay(ts.appointmentDate),
                color: { primary: '#5194ff', secondary: '#EBF4FA' },
                ts: ts
                }; 
                if(ts.appointmentStartDate && ts.appointmentFinishDate) {
                event.start = new Date(ts.appointmentStartDateS);
                event.end = new Date(ts.appointmentFinishDateS);
                }
                this.events.push(event);
            }
            );
            },
            err =>{
            this.ttService.checkErr(err,'error loading tasks', this.modalRef);
            this.alertsDismiss.push({
                type: 'danger',
                msg: err.error.errorMessage,
                timeout: 5000
            });
            
            },
    
            () => console.log('done loading tasks')
        );
    }
    deleteTimeTracking(tt) {
        let vm = this;
        if(this.type == 'Project'){
            this.reason = 'progress update';
            bootbox.confirm("Are you sure you want to delete timesheet " + tt.id + "?",
            function(result) {
            if(result) {
                vm.busy = vm.ttService.deleteTimeTracking(tt).subscribe(
                data => {
                vm.getTimeSheet();
                vm.close();
                },
                err => {
                vm.ttService.checkErr(err,'error delete time tracking', vm.modalRef);
                vm.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
                }
            );
            }
            });
        }else{
            bootbox.confirm("Are you sure you want to delete timesheet " + tt.id + "?",
            function(result) {
            if(result) {
                vm.busy = vm.ttService.deleteTimeTrackingTicket(tt).subscribe(
                data => {
                vm.getTimeSheet();
                vm.close();
                },
                err => {
                vm.ttService.checkErr(err,'error delete time tracking', vm.modalRef);
                vm.alertsDismiss.push({
                    type: 'danger',
                    msg: err.error.errorMessage,
                    timeout: 5000
                });
                }
            );
            }
            });
        }
    }

    createTimeTracking() {
        var dateStart = null;

        let totalMinutesStart1 = (this.timeStart1.hour * 60) + this.timeStart1.minute;
        let totalMinutesEnd1 = (this.timeEnd1.hour * 60) + this.timeEnd1.minute;

        let totalMinutesStart2 = 0;
        let totalMinutesEnd2 = 0;

        if(this.timeStart2 && this.timeEnd2) {
        totalMinutesStart2 = (this.timeStart2.hour * 60) + this.timeStart2.minute;
        totalMinutesEnd2 = (this.timeEnd2.hour * 60) + this.timeEnd2.minute;
        }

        if(totalMinutesEnd1 > totalMinutesStart1) {
        if(totalMinutesStart2 && totalMinutesEnd2 && totalMinutesStart2 > 0 && totalMinutesEnd2 > 0) {
            if(totalMinutesEnd2 < totalMinutesStart2 ||
            totalMinutesStart2 < totalMinutesEnd1 ||
            totalMinutesStart2 < totalMinutesStart1) {
                this.alertsDismiss.push({
                type: 'danger',
                msg: 'Invalid Dates!',
                timeout: 5000
                });
                return;
            }
        }

        if(this.modelDate && this.modelDate.day && this.modelDate.month) {
            dateStart = new Date(this.modelDate.year, this.modelDate.month-1, this.modelDate.day, 0, 0, 0);
        }

        this.busy = this.ttService.getTaskById(+this.selectedTask).subscribe(
            data => {
                let response:any = data;
                let phase = response.phase ? response.phase.id : null;
                let tt1 = {
                    "taskId":             response.id,
                    "customerId":         response.customer.custId,
                    "projectId":          response.project.id,
                    "mileId":             phase,
                    "strAmountHourStart": this.timeStart1.hour + ':' + this.timeStart1.minute,
                    "strAmountHourEnd":   this.timeEnd1.hour + ':' + this.timeEnd1.minute,
                    "appointmentDate":    dateStart,
                    "description":        this.description,
                    "ownerUserId":        this.ownerUserId,
                    "userId":             sessionStorage.getItem('userId'),
                    "companyId":          sessionStorage.getItem('compId'),
                    "creationUserId":     sessionStorage.getItem('userId'),
                    "typeAmount" :        'Duration'
                };

                let tt2 = null;
            
                if(totalMinutesStart2 > 0 && totalMinutesEnd2 > 0) {
                    tt2 = {
                    "taskId":             response.id,
                    "customerId":         response.customer.custId,
                    "projectId":          response.project.id,
                    "mileId":             phase,
                    "strAmountHourStart": this.timeStart2.hour + ':' + this.timeStart2.minute,
                    "strAmountHourEnd":   this.timeEnd2.hour + ':' + this.timeEnd2.minute,
                    "appointmentDate":    dateStart,
                    "description":        this.description,
                    "ownerUserId":        this.ownerUserId,
                    "userId":             sessionStorage.getItem('userId'),
                    "companyId":          sessionStorage.getItem('compId'),
                    "creationUserId":     sessionStorage.getItem('userId'),
                    "typeAmount" :        'Duration'
                    };
                }
                if(response) {
                    this.ttService.createTimeTracking(tt1).subscribe(
                    data => {
                        alert("Timesheet saved.");
                        this.reason = 'progress update';
                        this.close();           
                    },
                    err => {
                        this.ttService.checkErr(err,'error save time tracking', this.modalRef);
                        this.alertsDismiss.push({
                        type: 'danger',
                        msg: err.error.errorMessage,
                        timeout: 5000
                        });
                    }
                    );
                    if(tt2) {
                    this.ttService.createTimeTracking(tt2).subscribe(
                        data => { },
                        err => {
                        this.ttService.checkErr(err,'error save second time tracking', this.modalRef);
                        this.alertsDismiss.push({
                            type: 'danger',
                            msg: err.error.errorMessage,
                            timeout: 5000
                        });
                        }
                    );            
                    }
                }});
        } else {
        this.alertsDismiss.push({
            type: 'danger',
            msg: 'Invalid Dates!',
            timeout: 5000
        });
        }
    }


    createTimeTrackingTicket() {
        var dateStart = null;

        let totalMinutesStart1 = (this.timeStart1.hour * 60) + this.timeStart1.minute;
        let totalMinutesEnd1 = (this.timeEnd1.hour * 60) + this.timeEnd1.minute;

        let totalMinutesStart2 = 0;
        let totalMinutesEnd2 = 0;

        if(this.timeStart2 && this.timeEnd2) {
        totalMinutesStart2 = (this.timeStart2.hour * 60) + this.timeStart2.minute;
        totalMinutesEnd2 = (this.timeEnd2.hour * 60) + this.timeEnd2.minute;
        }

        if(totalMinutesEnd1 > totalMinutesStart1) {
            if(totalMinutesStart2 && totalMinutesEnd2 && totalMinutesStart2 > 0 && totalMinutesEnd2 > 0) {
                if(totalMinutesEnd2 < totalMinutesStart2 ||
                totalMinutesStart2 < totalMinutesEnd1 ||
                totalMinutesStart2 < totalMinutesStart1) {
                    this.alertsDismiss.push({
                    type: 'danger',
                    msg: 'Invalid Dates!',
                    timeout: 5000
                    });
                    return;
                }
            }

            if(this.modelDate && this.modelDate.day && this.modelDate.month) {
                dateStart = new Date(this.modelDate.year, this.modelDate.month-1, this.modelDate.day, 0, 0, 0);
            }

            let tt1 = {
                "ticket":           {'id' : this.selectedTicket},
                "customer":         {'custId' : this.selectedClient},
                "system":           {'id' : this.selectedSystem},
                "description":        this.ticketDescription,
                "comments":           this.comments,
                "timeStart":          this.timeStart1.hour + ':' + this.timeStart1.minute,
                "timeEnd":            this.timeEnd1.hour + ':' + this.timeEnd1.minute,
                "appointmentDate":    dateStart,
                "userId":             sessionStorage.getItem('userId'),
                "companyId":          sessionStorage.getItem('compId'),
                "creationUserId":     sessionStorage.getItem('userId')
            };

            if(tt1) {
                this.busy = this.ttService.createTimeTrackingTicket(tt1).subscribe(
                    data => { 
                        alert("Timesheet saved.");
                        console.log(data);
                        this.close();  
                    },
                    err => {
                    this.ttService.checkErr(err,'error first second time tracking', this.modalRef);
                    this.alertsDismiss.push({
                        type: 'danger',
                        msg: err.error.errorMessage,
                        timeout: 5000
                    });
                    }
                );            
            }

            let tt2 = null;
            if(totalMinutesStart2 > 0 && totalMinutesEnd2 > 0) {
                tt2 = {
                    "ticket":           {'id' : this.selectedTicket},
                    "customer":         {'custId' : this.selectedClient},
                    "system":           {'id' : this.selectedSystem},
                    "description":        this.ticketDescription,
                    "comments":           this.comments,
                    "timeStart":          this.timeStart2.hour + ':' + this.timeStart2.minute,
                    "timeEnd":            this.timeEnd2.hour + ':' + this.timeEnd2.minute,
                    "appointmentDate":    dateStart,
                    "user":               {'id' : sessionStorage.getItem('userId')},
                    "userId":             sessionStorage.getItem('userId'),
                    "companyId":          sessionStorage.getItem('compId'),
                    "creationUserId":     sessionStorage.getItem('userId')
                };
            }

            if(tt2){
                this.busy = this.ttService.createTimeTrackingTicket(tt2).subscribe(
                    data => { 
                        alert("Timesheet saved.");
                        this.close();  
                    },
                    err => {
                    this.ttService.checkErr(err,'error first second time tracking', this.modalRef);
                    this.alertsDismiss.push({
                        type: 'danger',
                        msg: err.error.errorMessage,
                        timeout: 5000
                    });
                    }
                );  
            }

        } else {
        this.alertsDismiss.push({
            type: 'danger',
            msg: 'Invalid Dates!',
            timeout: 5000
        });
        }
    }

}