import { Component, Inject } from '@angular/core';
import { InstanceConfigHolderService } from 'ng-busy';

@Component({
    selector: 'custom-busy',
    template: `
    <div class="modal-body modal-loader">
        <div class="loader">
            <style>
            .modal-loader{
                position: absolute;
                    width: 70px;
                    height: 70px;
                    top: 50%;
                    left: 50%;
                    margin: -35px 0 0 -35px;
            }
                .loader {                   
                    width: 70px;
                    height: 70px;         
                    border: 8px solid #f3f3f3;
                    border-radius: 100%;
                    border-top: 8px solid #3498db;
                    animation: spin 1s linear infinite;
                }                        
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
            </style>
        </div>      
    </div>  
    `,
  })

export class CustomBusyComponent{
    constructor(@Inject('instanceConfigHolder') private instanceConfigHolder: InstanceConfigHolderService) {}
   
    get message() {
        return this.instanceConfigHolder.config.message;
      }
  }