import { Validators } from '@angular/forms';
import { TimeTrackingService } from '../../../services/time-tracking.service';
import { TemplateRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TimeSheetDialogComponent } from '../timesheet-dialog/timesheet-dialog.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {

  ticketId: any;
  description: any;
  email = new FormControl('', Validators.required)

  constructor(private ttService: TimeTrackingService, private ngbModal: NgbModal, private modalRef: NgbActiveModal) { }

  ngOnInit() {

    this.ticketId = localStorage.getItem('ticketId')

    localStorage.removeItem('ticketId')

    console.log(this.ticketId)

    this.email.valueChanges.subscribe(() => {
      console.log("mudou")
    })

  }

  close() {
    this.modalRef.close('');
  }

  sendEmail() {
    const data = {
      toAddress: this.email.value,
      firstParam: this.ticketId,
      secondParam: this.description
    }

    console.log(data)

    this.ttService.sendEmail(data).subscribe(() => {
        this.close();
    });
  }

}
