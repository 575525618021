import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { AppComponent } from "../app.component";
import { Router, NavigationExtras } from "@angular/router";
import { Task } from "../model/model.task";
import { Md5 } from "ts-md5";

@Injectable()
export class TimeTrackingService {
  httpHeaders = new HttpHeaders();
  constructor(
    private http: HttpClient,
    public authService: AuthService,
    public router: Router
  ) {}

  findAnswer(id: any) {
    return this.http.get<any>(AppComponent.API_URL + `/questions/${id}`, {
      headers: this.guestLogin(),
    });
  }

  checkErr(err, message, modal) {
    if (err.url == null) {
      if (modal != undefined || modal != null) {
        modal.hide();
      }
      sessionStorage.clear();
      this.router.navigateByUrl("/login");
    }
  }

  sendEmail(request: any) {
    return this.http.post(AppComponent.API_URL + '/email', request, {
      headers: this.getOptions(),
    });
  }

  findTicket(id: any) {
    return this.http.get<any>(AppComponent.API_URL + `/ticket/${id}`, {
      headers: this.guestLogin(),
    });
  }

  sendForm(data: any) {
    return this.http.post(AppComponent.API_URL + "/questions", data, {
      headers: this.guestLogin(),
    });
  }

  base64Credential: string = btoa(
    "ConvidadoPS" + ":" + Md5.hashStr("12345").toString()
  );

  guestLogin() {
    return {
      "Content-Type": "application/json",
      Authorization: "Basic " + this.base64Credential,
    };
  }

  getOptions() {
    return {
      "Content-Type": "application/json",
      Authorization: "Basic " + sessionStorage.getItem("base64Credential"),
    };
  }

  /** Start Task Service */
  getCustomersThatUserIsResponsible() {
    return this.http.get(AppComponent.API_URL + "/user/customers-contract", {
      headers: this.getOptions(),
    });
  }

  getSystemsThatUserIsResponsible(customerId) {
    return this.http.get(
      `${AppComponent.API_URL}/system/systems-contract/customer/${customerId}`,
      { headers: this.getOptions() }
    );
  }

  getSystemsByCustomer(customerId: any) {
    return this.http.get(
      `${AppComponent.API_URL}/system/customer/${customerId}/all`,
      { headers: this.getOptions() }
    );
  }

  getTaskById(task: number) {
    return this.http.get(AppComponent.API_URL + "/task/" + task, {
      headers: this.getOptions(),
    });
  }

  getTasksByCompany(companyId: number) {
    return this.http.get(AppComponent.API_URL + "/task/company/" + companyId, {
      headers: this.getOptions(),
    });
  }

  getTasksBySpecification(specification, page: number, size: number) {
    let body = JSON.stringify(specification);
    return this.http.post(
      AppComponent.API_URL +
        "/task/specification?page=" +
        page +
        "&size=" +
        size,
      body,
      { headers: this.getOptions() }
    );
  }

  getTaskOverviewReport(specification, type) {
    let body = JSON.stringify(specification);
    return this.http.post(
      AppComponent.API_URL + "/task/task-report?type=" + type,
      body,
      { responseType: "blob", headers: this.getOptions() }
    );
  }

  getPredecessorsBySpecification(specification, taskId: number) {
    let body = JSON.stringify(specification);
    return this.http.post(
      AppComponent.API_URL + "/task/predecessors/specification/" + taskId,
      body,
      { headers: this.getOptions() }
    );
  }

  getProgressReports(projectId: number) {
    let url = "/report/progress/" + projectId;
    return this.http.get(AppComponent.API_URL + url, {
      headers: this.getOptions(),
    });
  }

  getTaskProgress(taskId: number) {
    return this.http.get(AppComponent.API_URL + "/task/progress/" + taskId, {
      headers: this.getOptions(),
    });
  }

  createTask(task: Task) {
    let body = JSON.stringify(task);
    return this.http.post(AppComponent.API_URL + "/task/", body, {
      headers: this.getOptions(),
    });
  }

  updateTask(task: Task) {
    let body = JSON.stringify(task);
    return this.http.put(AppComponent.API_URL + "/task/" + task.id, body, {
      headers: this.getOptions(),
    });
  }

  deleteTask(taskId: number) {
    return this.http.delete(AppComponent.API_URL + "/task/" + taskId, {
      headers: this.getOptions(),
    });
  }

  getTasksSituation(specification) {
    let body = JSON.stringify(specification);
    return this.http.post(AppComponent.API_URL + "/task/situation/", body, {
      headers: this.getOptions(),
    });
  }

  getTasksSituationByCompany(companyId: number) {
    return this.http.get(
      AppComponent.API_URL + "/task/situation/company/" + companyId,
      { headers: this.getOptions() }
    );
  }

  getTasksGanttChartByProject(projectId: number) {
    return this.http.get(
      AppComponent.API_URL + "/report/taskGanttChartData/project/" + projectId,
      { headers: this.getOptions() }
    );
  }
  /** End Task Service */

  /** Start Project Service */
  getAllProjectGraphs(projectId: number) {
    return this.http.get(
      AppComponent.API_URL + "/graphs/project/" + projectId,
      { headers: this.getOptions() }
    );
  }
  /** End Project Service */

  getTasksTaskId(taskId) {
    return this.http.get(AppComponent.API_URL + "/task/taskids/" + taskId, {
      headers: this.getOptions(),
    });
  }

  getTasksByTaskId(id) {
    return this.http.get(AppComponent.API_URL + "/task/tasksId/" + id, {
      headers: this.getOptions(),
    });
  }

  getTasksByProjId(id) {
    return this.http.get(AppComponent.API_URL + "/task/project/" + id, {
      headers: this.getOptions(),
    });
  }

  getTasksForCompId(compId) {
    return this.http.get(AppComponent.API_URL + "/task/company/" + compId, {
      headers: this.getOptions(),
    });
  }

  getTasksForCompIdPagination(compId, pagination) {
    return this.http.get(
      AppComponent.API_URL + "/task/pagination/" + compId + pagination,
      { headers: this.getOptions() }
    );
  }

  getTasksForCompIdAndPeriod(
    compId,
    dateStartA,
    dateStartB,
    dateFinishA,
    dateFinishB
  ) {
    return this.http.get(
      AppComponent.API_URL +
        "/task/company/" +
        compId +
        "/" +
        dateStartA +
        "/" +
        dateStartB +
        "/" +
        dateFinishA +
        "/" +
        dateFinishB,
      { headers: this.getOptions() }
    );
  }

  getTasksForCompIdAndPeriodStart(compId, dateStartA, dateStartB) {
    return this.http.get(
      AppComponent.API_URL +
        "/task/taskStatusStart/company/" +
        compId +
        "/" +
        dateStartA +
        "/" +
        dateStartB,
      { headers: this.getOptions() }
    );
  }
  getTasksForCompIdAndPeriodEnd(compId, dateFinishA, dateFinishB) {
    return this.http.get(
      AppComponent.API_URL +
        "/task/taskStatusEnd/company/" +
        compId +
        "/" +
        dateFinishA +
        "/" +
        dateFinishB,
      { headers: this.getOptions() }
    );
  }

  getTasksForCompIdDash(compId) {
    return this.http.get(
      AppComponent.API_URL + "/task/dash/company/" + compId,
      { headers: this.getOptions() }
    );
  }

  getTasksForCompIdCustId(compId, custId) {
    return this.http.get(
      AppComponent.API_URL + "/task/company/" + compId + "/customer/" + custId,
      { headers: this.getOptions() }
    );
  }

  getTasksForUser(userId) {
    return this.http.get(AppComponent.API_URL + "/task/user/" + userId, {
      headers: this.getOptions(),
    });
  }

  getTasksWithoutUser() {
    return this.http.get(AppComponent.API_URL + "/task/users/", {
      headers: this.getOptions(),
    });
  }

  getTasksForUserDash(userId) {
    return this.http.get(AppComponent.API_URL + "/task/dash/user/" + userId, {
      headers: this.getOptions(),
    });
  }

  getCountTasks(compId) {
    return this.http.get(
      AppComponent.API_URL + "/task/companyCount/" + compId,
      { headers: this.getOptions() }
    );
  }

  getCountTasksByPeriod(
    compId,
    dateStartA,
    dateStartB,
    dateFinishA,
    dateFinishB
  ) {
    return this.http.get(
      AppComponent.API_URL +
        "/task/companyCount/" +
        compId +
        "/" +
        dateStartA +
        "/" +
        dateStartB +
        "/" +
        dateFinishA +
        "/" +
        dateFinishB,
      { headers: this.getOptions() }
    );
  }

  getCountTasksByPeriodStart(compId, dateStartA, dateStartB) {
    return this.http.get(
      AppComponent.API_URL +
        "/task/statusStart/" +
        compId +
        "/" +
        dateStartA +
        "/" +
        dateStartB,
      { headers: this.getOptions() }
    );
  }

  getCountTasksByPeriodEnd(compId, dateFinishA, dateFinishB) {
    return this.http.get(
      AppComponent.API_URL +
        "/task/statusEnd/" +
        compId +
        "/" +
        dateFinishA +
        "/" +
        dateFinishB,
      { headers: this.getOptions() }
    );
  }

  getTaskStatus(compId) {
    return this.http.get(
      AppComponent.API_URL + "/task/companyCountTasks/" + compId,
      { headers: this.getOptions() }
    );
  }

  getTasksForUserTaskId(userId, taskId) {
    return this.http.get(
      AppComponent.API_URL + "/task/user/" + userId + "/father/" + taskId,
      { headers: this.getOptions() }
    );
  }

  getTasksForCompIdTaskId(compId, taskId) {
    return this.http.get(
      AppComponent.API_URL + "/task/company/" + compId + "/father/" + taskId,
      { headers: this.getOptions() }
    );
  }

  updateTaskResponsible(task, id) {
    let body = JSON.stringify(task);
    return this.http.put(
      AppComponent.API_URL + "/task/tasksResponsible/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  getTaskHistory() {
    return this.http.get(AppComponent.API_URL + "/taskhistory/taskhistories", {
      headers: this.getOptions(),
    });
  }

  getTaskHistoryPagination(taskId: number, pagination: string) {
    return this.http.get(
      AppComponent.API_URL + "/taskhistory/pagination/" + taskId + pagination,
      { headers: this.getOptions() }
    );
  }

  /* this endpoint doesn't exist on backend
	getTaskHistoryByTaskId(taskId) {
	  return this.http.get(AppComponent.API_URL + '/taskhistory/taskhistories/tasks/' + taskId, { headers: this.getOptions() });
	}*/

  getTaskHistoryByTahi(tahiId) {
    return this.http.get(
      AppComponent.API_URL + "/taskHistory/taskhistories/" + tahiId,
      { headers: this.getOptions() }
    );
  }

  createTaskHistoryOldVersion(tahi) {
    let body = JSON.stringify(tahi);
    return this.http.post(
      AppComponent.API_URL + "/taskhistory/taskhistories/",
      body,
      { headers: this.getOptions() }
    );
  }

  createTaskHistory(tahi, userId, taskId) {
    let body = JSON.stringify(tahi);
    return this.http.post(
      AppComponent.API_URL + "/taskhistory/pagination/" + taskId + "/" + userId,
      body,
      { headers: this.getOptions() }
    );
  }

  closeTasksByProject(projId, userId) {
    return this.http.put(
      AppComponent.API_URL +
        "/project/closeProjectTasks?projId=" +
        projId +
        "&userId=" +
        userId,
      { headers: this.getOptions() }
    );
  }

  createTicket(ticket) {
    let body = JSON.stringify(ticket);
    return this.http.post(AppComponent.API_URL + "/ticket", body, {
      headers: this.getOptions(),
    });
  }

  createGenericTicket(ticket) {
    let body = JSON.stringify(ticket);
    return this.http.post(AppComponent.API_URL + "/ticket/generic", body, {
      headers: this.getOptions(),
    });
  }

  send(ticket, statusName) {
    let body = JSON.stringify(ticket);
    return this.http.post(AppComponent.API_URL + "/send/" + statusName, body, {
      headers: this.getOptions(),
    });
  }

  updateTicket(ticket, ticketId) {
    let body = JSON.stringify(ticket);
    return this.http.put(AppComponent.API_URL + "/ticket/" + ticketId, body, {
      headers: this.getOptions(),
    });
  }

  getTicket(ticketId) {
    return this.http.get(AppComponent.API_URL + "/ticket/" + ticketId, {
      headers: this.getOptions(),
    });
  }

  getTickets(page, size, companyId) {
    return this.http.get(AppComponent.API_URL + '/ticket/company/' + companyId + '?page=' + page + "&size=" + size, 
    { headers: this.getOptions() });
  }

  getQuestionTickets(page, size, companyId) {
    console.log("Caio aqui")
    return this.http.get(
      AppComponent.API_URL +
        "/ticket/company/resolved/" +
        companyId +
        "?page=" +
        page +
        "&size=" +
        size,
      { headers: this.getOptions() }
    );
  }

  getTicketsBySystem(systemId) {
    return this.http.get(AppComponent.API_URL + "/ticket/system/" + systemId, {
      headers: this.getOptions(),
    });
  }

  getTicketQuestionsFiltered(page, size, params) {
    let body = JSON.stringify(params);
    return this.http.post(
      AppComponent.API_URL +
        "/ticket/ticketFilter/close?page=" +
        page +
        "&size=" +
        size,
      body,
      { headers: this.getOptions() }
    );
  }

  getTicketsFiltered(page, size, params) {
		let body = JSON.stringify(params);
		return this.http.post(
      AppComponent.API_URL + '/ticket/ticketFilter?page=' + page + "&size=" + size, body, { headers: this.getOptions() });
	}

  getTicketsFilter(page, size, params) {
    let body = JSON.stringify(params);
    return this.http.post(
      AppComponent.API_URL +
        "/ticket/ticketFilter/close?page=" +
        page +
        "&size=" +
        size,
      body,
      { headers: this.getOptions() }
    );
  }

  getProjectFiltered(page, size, params) {
    let body = JSON.stringify(params);
    return this.http.post(
      AppComponent.API_URL +
        "/project/projectFilter?page=" +
        page +
        "&size=" +
        size,
      body,
      { headers: this.getOptions() }
    );
  }

  getProjectPortfolioFiltered(params, pageable) {
    let body = JSON.stringify(params);
    return this.http.post(
      AppComponent.API_URL + "/report/projectPortfolio" + pageable,
      body,
      { headers: this.getOptions() }
    );
  }

  /*RAFAEL REI DA TASK TERROR DO TIME SHEET*/
  getUserGanntExpected(params) {
    let body = JSON.stringify(params);
    return this.http.post(
      AppComponent.API_URL + "/report/ganntExpected",
      body,
      { headers: this.getOptions() }
    );
  }

  getProjectPagination(page, size, compId) {
    return this.http.get(
      AppComponent.API_URL +
        "/project/pagination/" +
        compId +
        "?page=" +
        page +
        "&size=" +
        size,
      { headers: this.getOptions() }
    );
  }

  deleteTicket(ticketId) {
    return this.http.delete(AppComponent.API_URL + "/ticket/" + ticketId, {
      headers: this.getOptions(),
    });
  }

  openJasperReport(kindOfReport, specifications) {
    let url = AppComponent.API_URL + "/jasper-report/" + kindOfReport;
    let body = JSON.stringify(specifications);
    return this.http.post(url, body, {
      responseType: "blob",
      headers: this.getOptions(),
    });
  }

  jasperReport(kindOfReport, specifications) {
    if (specifications == undefined) {
      specifications = {
        id: null,
        customer: null,
        system: null,
        ticketStatus: null,
        replyDateFrom: null,
        replyDateTo: null,
      };
    }
    let url = AppComponent.API_URL + "/jasper-report/" + kindOfReport;
	console.log(specifications);
	console.log(url);
    return this.http.post(url, specifications, {
      responseType: "blob",
      headers: this.getOptions(),
    });
  }

  createTicketHistory(ticketLog, ticketId, userId) {
    let body = JSON.stringify(ticketLog);
    return this.http.post(
      AppComponent.API_URL + "/ticketLog/" + ticketId + "/" + userId,
      body,
      { headers: this.getOptions() }
    );
  }

  getTicketHistory(ticketId) {
    return this.http.get(AppComponent.API_URL + "/ticketLog/" + ticketId, {
      headers: this.getOptions(),
    });
  }

  getTicketCommentHistories(ticketId) {
    return this.http.get(
      AppComponent.API_URL + "/ticketLog/commentHistories/" + ticketId,
      { headers: this.getOptions() }
    );
  }

  getTicketStatus() {
    return this.http.get(AppComponent.API_URL + "/ticketStatus", {
      headers: this.getOptions(),
    });
  }

  createSystem(system) {
    let body = JSON.stringify(system);
    return this.http.post(AppComponent.API_URL + "/system", body, {
      headers: this.getOptions(),
    });
  }

  updateSystem(system, systemId) {
    let body = JSON.stringify(system);
    return this.http.put(AppComponent.API_URL + "/system/" + systemId, body, {
      headers: this.getOptions(),
    });
  }

  getSystem(systemId) {
    return this.http.get(AppComponent.API_URL + "/system/" + systemId, {
      headers: this.getOptions(),
    });
  }

  getSystems(companyId) {
    return this.http.get(
      AppComponent.API_URL + "/system/company/" + companyId,
      { headers: this.getOptions() }
    );
  }

  getSystemsByCustomerAndSystemId(customerId, systemId) {
    return this.http.get(
      AppComponent.API_URL + "/system/customer/" + customerId + "/" + systemId,
      { headers: this.getOptions() }
    );
  }

  getSystemsPagination(pagination) {
    return this.http.get(
      AppComponent.API_URL +
        "/system/pagination/" +
        sessionStorage.getItem("compId") +
        pagination,
      { headers: this.getOptions() }
    );
  }

  getSystemsWithoutSla(companyId) {
    return this.http.get(AppComponent.API_URL + "/system/sla/" + companyId, {
      headers: this.getOptions(),
    });
  }

  getSystemsWithSla(page, size) {
    return this.http.get(
      AppComponent.API_URL +
        "/system/pagination/sla/" +
        sessionStorage.getItem("compId") +
        "?page=" +
        page +
        "&size=" +
        size,
      { headers: this.getOptions() }
    );
  }

  createSystemHistory(systemLog, systemId, userId) {
    let body = JSON.stringify(systemLog);
    return this.http.post(
      AppComponent.API_URL + "/systemLog/" + systemId + "/" + userId,
      body,
      { headers: this.getOptions() }
    );
  }

  getSystemHistory(systemId) {
    return this.http.get(AppComponent.API_URL + "/systemLog/" + systemId, {
      headers: this.getOptions(),
    });
  }

  getPrioritySlas() {
    return this.http.get(AppComponent.API_URL + "/prioritySla", {
      headers: this.getOptions(),
    });
  }

  createSla(sla) {
    let body = JSON.stringify(sla);
    return this.http.post(AppComponent.API_URL + "/sla", body, {
      headers: this.getOptions(),
    });
  }

  updateSla(sla, slaId) {
    let body = JSON.stringify(sla);
    return this.http.put(AppComponent.API_URL + "/sla/" + slaId, body, {
      headers: this.getOptions(),
    });
  }

  createSlaHistory(slaLog, slaId, userId) {
    let body = JSON.stringify(slaLog);
    return this.http.post(
      AppComponent.API_URL + "/slaLog/" + slaId + "/" + userId,
      body,
      { headers: this.getOptions() }
    );
  }

  getSlaHistory(slaIds, page, size) {
    return this.http.get(
      AppComponent.API_URL +
        "/slaLog/pagination/" +
        slaIds +
        "?page=" +
        page +
        "&size=" +
        size,
      { headers: this.getOptions() }
    );
  }

  getSla(slaId) {
    return this.http.get(AppComponent.API_URL + "/sla/" + slaId, {
      headers: this.getOptions(),
    });
  }

  getSlasBySystem(systemId) {
    return this.http.get(AppComponent.API_URL + "/sla/system/" + systemId, {
      headers: this.getOptions(),
    });
  }

  getSlaByPriorityAndSystem(priorityId, systemId) {
    return this.http.get(
      AppComponent.API_URL +
        "/sla/prioritySla/" +
        priorityId +
        "/system/" +
        systemId,
      { headers: this.getOptions() }
    );
  }

  createContract(contract) {
    let body = JSON.stringify(contract);
    return this.http.post(AppComponent.API_URL + "/contract", body, {
      headers: this.getOptions(),
    });
  }

  updateContract(contract, contractId) {
    let body = JSON.stringify(contract);
    return this.http.put(
      AppComponent.API_URL + "/contract/" + contractId,
      body,
      { headers: this.getOptions() }
    );
  }

  getContract(contractId) {
    return this.http.get(AppComponent.API_URL + "/contract/" + contractId, {
      headers: this.getOptions(),
    });
  }

  getContracts(compId) {
    return this.http.get(AppComponent.API_URL + "/contract/company/" + compId, {
      headers: this.getOptions(),
    });
  }

  getActiveContracts(compId) {
    return this.http.get(
      AppComponent.API_URL + "/contract/company/active/" + compId,
      { headers: this.getOptions() }
    );
  }

  getContractsPagination(pagination, compId) {
    return this.http.get(
      AppComponent.API_URL +
        "/contract/pagination/company/" +
        compId +
        pagination,
      { headers: this.getOptions() }
    );
  }

  createContractHistory(contractLog, contractId, userId) {
    let body = JSON.stringify(contractLog);
    return this.http.post(
      AppComponent.API_URL + "/contractLog/" + contractId + "/" + userId,
      body,
      { headers: this.getOptions() }
    );
  }

  getContractHistory(contractId) {
    return this.http.get(
      AppComponent.API_URL + "/contractLog/pagination/" + contractId,
      { headers: this.getOptions() }
    );
  }

  // Isnt being used anywhere
  getProjects() {
    return this.http.get(AppComponent.API_URL + "/project/projects", {
      headers: this.getOptions(),
    });
  }

  getProjectsForCompId(compId) {
    return this.http.get(AppComponent.API_URL + "/project/company/" + compId, {
      headers: this.getOptions(),
    });
  }

  getProjectsForCompIdCustId(compId, custId) {
    return this.http.get(
      AppComponent.API_URL +
        "/project/company/" +
        compId +
        "/customer/" +
        custId,
      { headers: this.getOptions() }
    );
  }

  getProjectsForCompIdCustIdAndStatus(compId, custId, status) {
    return this.http.get(
      AppComponent.API_URL +
        "/project/status/" + status +
        "/company/" + compId +
        "/customer/" +
        custId,
      { headers: this.getOptions() }
    );
  }

  getProjectsForCompIdCustIdStatId(compId, custId) {
    return this.http.get(
      AppComponent.API_URL +
        "/project/company/" +
        compId +
        "/customer/" +
        custId +
        "/status",
      { headers: this.getOptions() }
    );
  }

  getProjectsNotClosed(compId) {
    return this.http.get(
      AppComponent.API_URL + "/project/company/" + compId + "/status",
      { headers: this.getOptions() }
    );
  }

  getProjectsById(id) {
    return this.http.get(AppComponent.API_URL + "/project/projects/" + id, {
      headers: this.getOptions(),
    });
  }

  getProjectsByCompIdAndParams(id) {
    return this.http.get(
      AppComponent.API_URL + "/project/projects/params/" + id,
      { headers: this.getOptions() }
    );
  }

  getProjectHistory(pagination) {
    return this.http.get(AppComponent.API_URL + "/projectLog/" + pagination, {
      headers: this.getOptions(),
    });
  }

  createProjectHistory(projectLog, projId, userId) {
    let body = JSON.stringify(projectLog);
    return this.http.post(
      AppComponent.API_URL + "/projectLog/" + projId + "/" + userId,
      body,
      { headers: this.getOptions() }
    );
  }

  getProjectsForResource(userId) {
    return this.http.get(AppComponent.API_URL + "/project/user/" + userId, {
      headers: this.getOptions(),
    });
  }

  // Isnt being used anywhere
  getProjectsForCustomerResource(userId, customerId) {
    return this.http.get(
      AppComponent.API_URL +
        "/project/user/" +
        userId +
        "/customer/" +
        customerId,
      { headers: this.getOptions() }
    );
  }

  getCompanys() {
    return this.http.get(AppComponent.API_URL + "/company/companys", {
      headers: this.getOptions(),
    });
  }

  getCompany(company) {
    return this.http.get(
      AppComponent.API_URL + "/company/companys/" + company.compId,
      { headers: this.getOptions() }
    );
  }

  deleteCompany(company) {
    return this.http.delete(
      AppComponent.API_URL + "/company/companys/" + company.compId,
      { headers: this.getOptions() }
    );
  }

  updateCompany(company, id) {
    let body = JSON.stringify(company);
    return this.http.put(
      AppComponent.API_URL + "/company/companys/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  createCompany(company) {
    let body = JSON.stringify(company);
    return this.http.post(AppComponent.API_URL + "/company/companys/", body, {
      headers: this.getOptions(),
    });
  }

  getAllocations() {
    return this.http.get(AppComponent.API_URL + "/allocation/allocations", {
      headers: this.getOptions(),
    });
  }
  getAllocationsForCompId(compId) {
    return this.http.get(
      AppComponent.API_URL + "/allocation/company/" + compId,
      { headers: this.getOptions() }
    );
  }
  getAllocationsForUser(userId) {
    return this.http.get(
      AppComponent.API_URL + "/allocation/allocationsUserId/" + userId,
      { headers: this.getOptions() }
    );
  }

  createAllocation(allocation) {
    let body = JSON.stringify(allocation);
    return this.http.post(
      AppComponent.API_URL + "/allocation/allocations/",
      body,
      { headers: this.getOptions() }
    );
  }

  updateAllocation(allocation, id) {
    let body = JSON.stringify(allocation);
    return this.http.put(
      AppComponent.API_URL + "/allocation/allocations/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  deleteAllocation(allocation) {
    return this.http.delete(
      AppComponent.API_URL + "/allocation/allocations/" + allocation.alloId,
      { headers: this.getOptions() }
    );
  }

  getClients() {
    return this.http.get(AppComponent.API_URL + "/task/customers", {
      headers: this.getOptions(),
    });
  }

  getDepartments() {
    return this.http.get(AppComponent.API_URL + "/departament/departaments", {
      headers: this.getOptions(),
    });
  }

  getDepartmentsForCompId(compId) {
    return this.http.get(
      AppComponent.API_URL + "/departament/company/" + compId,
      { headers: this.getOptions() }
    );
  }

  getDepartmentsForCompIdCustId(compId, custId) {
    return this.http.get(
      AppComponent.API_URL +
        "/departament/company/" +
        compId +
        "/customer/" +
        custId,
      { headers: this.getOptions() }
    );
  }
  getDepartmentsById(id) {
    return this.http.get(
      AppComponent.API_URL + "/departament/departamentsId/" + id,
      { headers: this.getOptions() }
    );
  }
  createDepartment(department) {
    let body = JSON.stringify(department);
    return this.http.post(
      AppComponent.API_URL + "/departament/departaments/",
      body,
      { headers: this.getOptions() }
    );
  }

  updateDepartment(department, id) {
    let body = JSON.stringify(department);
    return this.http.put(
      AppComponent.API_URL + "/departament/departaments/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  deleteDepartment(department) {
    return this.http.delete(
      AppComponent.API_URL + "/departament/departaments/" + department.id,
      { headers: this.getOptions() }
    );
  }

  getCustomers() {
    return this.http.get(AppComponent.API_URL + "/customer/customers", {
      headers: this.getOptions(),
    });
  }

  getCustomersForCompId(compId) {
    return this.http.get(AppComponent.API_URL + "/customer/company/" + compId, {
      headers: this.getOptions(),
    });
  }

  getCustomersAlphabeticalOrder(compId, customerId) {
    return this.http.get(
      AppComponent.API_URL +
        "/pagination/alphabetical/" +
        compId +
        "/" +
        customerId,
      { headers: this.getOptions() }
    );
  }

  getCustomersAlphabetical(compId) {
    return this.http.get(
      AppComponent.API_URL + "/customer/alphabetical/" + compId,
      { headers: this.getOptions() }
    );
  }

  getCustomersPagination(pagination) {
    return this.http.get(
      AppComponent.API_URL +
        "/pagination/" +
        sessionStorage.getItem("compId") +
        pagination,
      { headers: this.getOptions() }
    );
  }

  getCustomersById(id) {
    return this.http.get(AppComponent.API_URL + "/customer/customers/" + id, {
      headers: this.getOptions(),
    });
  }

  getCustomersForResource(userId) {
    return this.http.get(AppComponent.API_URL + "/customer/user/" + userId, {
      headers: this.getOptions(),
    });
  }

  createCustomer(customer) {
    let body = JSON.stringify(customer);
    return this.http.post(AppComponent.API_URL + "/customer/customers/", body, {
      headers: this.getOptions(),
    });
  }

  updateCustomer(customer, id) {
    let body = JSON.stringify(customer);
    return this.http.put(
      AppComponent.API_URL + "/customer/customers/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  deleteCustomer(customer) {
    return this.http.delete(
      AppComponent.API_URL + "/customer/customers/" + customer.id,
      { headers: this.getOptions() }
    );
  }

  getCustomerHistory(customerId) {
    return this.http.get(AppComponent.API_URL + "/customerLog/" + customerId, {
      headers: this.getOptions(),
    });
  }

  createCustomerHistory(customerLog, customerId, userId) {
    let body = JSON.stringify(customerLog);
    return this.http.post(
      AppComponent.API_URL + "/customerLog/" + customerId + "/" + userId,
      body,
      { headers: this.getOptions() }
    );
  }

  getStatusForCompId(compId) {
    return this.http.get(AppComponent.API_URL + "/status/company/" + compId, {
      headers: this.getOptions(),
    });
  }

  getInactiveStatus(compId) {
    return this.http.get(
      AppComponent.API_URL + "/status/inactive/company/" + compId,
      { headers: this.getOptions() }
    );
  }

  getCustomersComp(compId) {
    return this.http.get(
      AppComponent.API_URL + "/customer/customersComp/" + compId,
      { headers: this.getOptions() }
    );
  }

  getParameters() {
    return this.http.get(AppComponent.API_URL + "/parameter/parameters", {
      headers: this.getOptions(),
    });
  }

  getParametersByPadoId(padoName, compId) {
    return this.http.get(
      AppComponent.API_URL +
        "/parameter/domain/" +
        padoName +
        "/company/" +
        compId,
      { headers: this.getOptions() }
    );
  }

  getParameterById(id) {
    return this.http.get(
      AppComponent.API_URL + "/parameter/parametersId/" + id,
      { headers: this.getOptions() }
    );
  }

  createParameter(parameter) {
    let body = JSON.stringify(parameter);
    return this.http.post(
      AppComponent.API_URL + "/parameter/parameter/",
      body,
      { headers: this.getOptions() }
    );
  }

  deleteParameter(parameter) {
    return this.http.delete(
      AppComponent.API_URL + "/parameter/parameter/" + parameter.id,
      { headers: this.getOptions() }
    );
  }

  updateParameter(parameter, id) {
    let body = JSON.stringify(parameter);
    return this.http.put(
      AppComponent.API_URL + "/parameter/parameter/" + parameter.id,
      body,
      { headers: this.getOptions() }
    );
  }

  getTimeTrackingUserFilter(compId, userId, dateStart, dateFinish) {
    return this.http.get(
      AppComponent.API_URL +
        "/timetracking/company/" +
        compId +
        "/user/" +
        userId +
        "/" +
        dateStart +
        "/" +
        dateFinish,
      { headers: this.getOptions() }
    );
  }
  getTimeTrackingFilter(compId, dateStart, dateFinish) {
    return this.http.get(
      AppComponent.API_URL +
        "/timetracking/company/" +
        compId +
        "/" +
        dateStart +
        "/" +
        dateFinish,
      { headers: this.getOptions() }
    );
  }

  getTimeTrackingUser(compId, userId) {
    return this.http.get(
      AppComponent.API_URL +
        "/timetracking/company/" +
        compId +
        "/user/" +
        userId,
      { headers: this.getOptions() }
    );
  }
  getTimeTracking(compId) {
    return this.http.get(
      AppComponent.API_URL + "/timetracking/company/" + compId,
      { headers: this.getOptions() }
    );
  }

  createTimeTracking(timeTracking) {
    let body = JSON.stringify(timeTracking);
    return this.http.post(
      AppComponent.API_URL + "/timetracking/timetrackings/",
      body,
      { headers: this.getOptions() }
    );
  }

  createTimeTrackingTicket(timeTracking) {
    let body = JSON.stringify(timeTracking);
    console.log("body");
    return this.http.post(
      AppComponent.API_URL + "/timetrackingtickets/",
      body,
      { headers: this.getOptions() }
    );
  }

  getTimeTrackingTicket(userId) {
    return this.http.get(
      AppComponent.API_URL + "/timetrackingtickets/user/" + userId,
      { headers: this.getOptions() }
    );
  }

  updateTimeTrackingTicket(timeTracking, id) {
    let body = JSON.stringify(timeTracking);
    return this.http.put(
      AppComponent.API_URL + "/timetrackingtickets/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  updateTimeTracking(timeTracking, id) {
    let body = JSON.stringify(timeTracking);
    return this.http.put(
      AppComponent.API_URL + "/timetracking/timetrackings/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  deleteTimeTracking(timeTracking) {
    return this.http.delete(
      AppComponent.API_URL + "/timetracking/timetrackings/" + timeTracking.id,
      { headers: this.getOptions() }
    );
  }

  deleteTimeTrackingTicket(timeTracking) {
    return this.http.delete(
      AppComponent.API_URL +
        "/timetrackingtickets/timetrackings/" +
        timeTracking.id,
      { headers: this.getOptions() }
    );
  }

  //marca
  // Isnt being used anywhere
  getProject(id) {
    return this.http.get(AppComponent.API_URL + "/project/projects/" + id, {
      headers: this.getOptions(),
    });
  }

  deleteProject(projectId) {
    return this.http.delete(AppComponent.API_URL + "/project/" + projectId, {
      headers: this.getOptions(),
    });
  }

  updateProject(project, id) {
    let body = JSON.stringify(project);
    return this.http.put(
      AppComponent.API_URL + "/project/projects/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  createProject(project) {
    let body = JSON.stringify(project);
    return this.http.post(AppComponent.API_URL + "/project/projects/", body, {
      headers: this.getOptions(),
    });
  }

  createTemplate(projectId) {
    return this.http.post(
      AppComponent.API_URL +
        "/project/createTemplate/" +
        projectId +
        "/" +
        sessionStorage.getItem("userId"),
      { headers: this.getOptions() }
    );
  }

  updateUser(user, id) {
    let body = JSON.stringify(user);
    return this.http.put(AppComponent.API_URL + "/user/users/" + id, body, {
      headers: this.getOptions(),
    });
  }

  changePassword(user, id) {
    let body = JSON.stringify(user);
    return this.http.put(
      AppComponent.API_URL + "/user/users/changePassword/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  createUser(user) {
    let body = JSON.stringify(user);
    return this.http.post(AppComponent.API_URL + "/user/users/", body, {
      headers: this.getOptions(),
    });
  }

  getUserProjectManager(compId, projId) {
    return this.http.get(
      AppComponent.API_URL + "/user/company/" + compId + "/project/" + projId,
      { headers: this.getOptions() }
    );
  }

  getUsers() {
    return this.http.get(AppComponent.API_URL + "/user/users", {
      headers: this.getOptions(),
    });
  }

  getActiveUsers(compId) {
    return this.http.get(
      AppComponent.API_URL + "/user/active/company/" + compId,
      { headers: this.getOptions() }
    );
  }

  getUserByCompId(compId) {
    return this.http.get(AppComponent.API_URL + "/user/company/" + compId, {
      headers: this.getOptions(),
    });
  }

  getUserByCompIdAndProfile(compId) {
    return this.http.get(
      AppComponent.API_URL + "/user/company/" + compId + "/analyst",
      { headers: this.getOptions() }
    );
  }

  getUserByCompIdAndTeamId(compId, teamId) {
    return this.http.get(
      AppComponent.API_URL + "/user/company/" + compId + "/team/" + teamId,
      { headers: this.getOptions() }
    );
  }
  getUserByStatus(compId) {
    return this.http.get(
      AppComponent.API_URL + "/user/status/company/" + compId,
      { headers: this.getOptions() }
    );
  }

  getUserByTeamId(teamId) {
    return this.http.get(AppComponent.API_URL + "/user/team/" + teamId, {
      headers: this.getOptions(),
    });
  }

  getUserById(user) {
    return this.http.get(AppComponent.API_URL + "/user/users/" + user.id, {
      headers: this.getOptions(),
    });
  }

  getUserName(userId) {
    return this.http.get(AppComponent.API_URL + "/user/name/" + userId, {
      headers: this.getOptions(),
    });
  }

  getUserByIdID(userId) {
    return this.http.get(AppComponent.API_URL + "/user/users/" + userId, {
      headers: this.getOptions(),
    });
  }

  deleteUser(user) {
    return this.http.delete(AppComponent.API_URL + "/user/users/" + user.id, {
      headers: this.getOptions(),
    });
  }

  getPhasesByCompanyIdPagination(companyId, page, size) {
    return this.http.get(
      AppComponent.API_URL +
        "/phase/phases/" +
        companyId +
        "?page=" +
        page +
        "&size=" +
        size,
      { headers: this.getOptions() }
    );
  }

  getPhasesByProject(projId) {
    return this.http.get(
      AppComponent.API_URL + "/phase/phases/project/" + projId,
      { headers: this.getOptions() }
    );
  }

  getPhasesByCompId(compId) {
    return this.http.get(AppComponent.API_URL + "/phase/company/" + compId, {
      headers: this.getOptions(),
    });
  }

  getPhaseById(id) {
    return this.http.get(AppComponent.API_URL + "/phase/" + id, {
      headers: this.getOptions(),
    });
  }

  createPhase(phase) {
    let body = JSON.stringify(phase);
    return this.http.post(AppComponent.API_URL + "/phase/", body, {
      headers: this.getOptions(),
    });
  }

  updatePhase(phase, id) {
    let body = JSON.stringify(phase);
    return this.http.put(AppComponent.API_URL + "/phase/" + id, body, {
      headers: this.getOptions(),
    });
  }

  deletePhase(phase) {
    return this.http.delete(AppComponent.API_URL + "/phase/" + phase.id, {
      headers: this.getOptions(),
    });
  }

  /**
   * User Team
   */
  getUserTeams() {
    return this.http.get(AppComponent.API_URL + "/userteam/userteams", {
      headers: this.getOptions(),
    });
  }

  getUserTeamsById(teamId) {
    return this.http.get(
      AppComponent.API_URL + "/userteam/userteams" + teamId,
      { headers: this.getOptions() }
    );
  }

  updateUserTeam(userteam, id) {
    let body = JSON.stringify(userteam);
    return this.http.put(
      AppComponent.API_URL + "/userteam/userteams/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  createUserTeam(userteam) {
    let body = JSON.stringify(userteam);
    return this.http.post(AppComponent.API_URL + "/userteam/userteams/", body, {
      headers: this.getOptions(),
    });
  }

  deleteUserTeam(userteam) {
    return this.http.delete(
      AppComponent.API_URL + "/userteam/userteams/" + userteam.id,
      { headers: this.getOptions() }
    );
  }

  /** End User Team */

  /** Profile */
  getProfilesByCompId(compId) {
    return this.http.get(AppComponent.API_URL + "/profile/company/" + compId, {
      headers: this.getOptions(),
    });
  }
  getProfiles() {
    return this.http.get(AppComponent.API_URL + "/profile/profiles", {
      headers: this.getOptions(),
    });
  }

  updateProfile(profile, id) {
    let body = JSON.stringify(profile);
    return this.http.put(
      AppComponent.API_URL + "/profile/profiles/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  createProfile(userProfile) {
    let body = JSON.stringify(userProfile);
    return this.http.post(AppComponent.API_URL + "/profile/profiles/", body, {
      headers: this.getOptions(),
    });
  }

  deleteProfile(userProfile) {
    return this.http.delete(
      AppComponent.API_URL + "/profile/profiles/" + userProfile.id,
      { headers: this.getOptions() }
    );
  }
  /** End Profile */

  /**
   * Contact
   */
  getContacts(customerId) {
    return this.http.get(
      AppComponent.API_URL + "/contact/contacts/customer/" + customerId,
      { headers: this.getOptions() }
    );
  }

  updateContact(contact, id) {
    let body = JSON.stringify(contact);
    return this.http.put(
      AppComponent.API_URL + "/contact/contacts/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  createContact(contact) {
    let body = JSON.stringify(contact);
    return this.http.post(AppComponent.API_URL + "/contact/contacts", body, {
      headers: this.getOptions(),
    });
  }

  deleteContact(contact) {
    return this.http.delete(
      AppComponent.API_URL + "/contact/contacts/" + contact.id,
      { headers: this.getOptions() }
    );
  }

  /** End Contact */

  /** Team */
  getTeamsByCompId(compId) {
    return this.http.get(AppComponent.API_URL + "/team/company/" + compId, {
      headers: this.getOptions(),
    });
  }
  getTeams() {
    return this.http.get(AppComponent.API_URL + "/team/teams", {
      headers: this.getOptions(),
    });
  }
  getTeamsById(id) {
    return this.http.get(AppComponent.API_URL + "/team/teamsId/" + id, {
      headers: this.getOptions(),
    });
  }

  updateTeam(team, id) {
    let body = JSON.stringify(team);
    return this.http.put(AppComponent.API_URL + "/team/teams/" + id, body, {
      headers: this.getOptions(),
    });
  }

  createTeam(userteam) {
    let body = JSON.stringify(userteam);
    return this.http.post(AppComponent.API_URL + "/team/teams/", body, {
      headers: this.getOptions(),
    });
  }

  deleteTeam(userteam) {
    return this.http.delete(
      AppComponent.API_URL + "/team/teams/" + userteam.id,
      { headers: this.getOptions() }
    );
  }
  /** End Team */

  /** Report */
  getAllocationsGantt(compId) {
    return this.http.get(
      AppComponent.API_URL + "/allocation/company/" + compId,
      { headers: this.getOptions() }
    );
  }

  getTasksGantt(compId) {
    return this.http.get(
      AppComponent.API_URL + "/report/tasks/company/" + compId,
      { headers: this.getOptions() }
    );
  }

  getResourceReport(compId) {
    return this.http.get(
      AppComponent.API_URL + "/allocation/company/" + compId,
      { headers: this.getOptions() }
    );
  }

  /* not being used in any functional view
	getResourceGantt(compId, team, userId, start, finish, teamAsParameter, usersAsParameter) {
	  let url = '/report/progress?comp=' + compId;
  
	  //if (userId) url += '&user=' + userId;
	  //else if (team) url += '&team=' + team.map(u => u.name).join(';');
  
	  url += '&type=gantt';
  
	  url += '&start=' + start;
  
	  url += '&finish=' + finish;
  
	  url += '&teamAsParameter=' + teamAsParameter
  
	  url += '&usersAsParameter=' + usersAsParameter
	  
	  return this.http.get(AppComponent.API_URL + url, { headers: this.getOptions() });
	}*/

  // NOT BEING USED IN ANY FUNCTIONAL VIEW ANYMORE
  getResourceGantt(compId, start, finish, teamAsParameter, usersAsParameter) {
    let url = "/report/progress?comp=" + compId;

    //if (userId) url += '&user=' + userId;
    //else if (team) url += '&team=' + team.map(u => u.name).join(';');

    url += "&type=gantt";

    url += "&start=" + start;

    url += "&finish=" + finish;

    url += "&teamAsParameter=" + teamAsParameter;

    url += "&usersAsParameter=" + usersAsParameter;
    console.log(url);

    return this.http.get(AppComponent.API_URL + url, {
      headers: this.getOptions(),
    });
  }

  /* NOT BEING USED IN ANY FUNCTIONAL VIEW ANYMORE
	getProjectPortfolio(compId, start, finish, status, customer) {
	  let url = '/report/projectPortfolio?comp=' + compId;
  
	  url += '&start=' + start;
  
	  url += '&finish=' + finish;
  
	  url += '&status=' + status;
  
	  url += '&cust=' + customer;
  
	  return this.http.get(AppComponent.API_URL + url, { headers: this.getOptions() });
	}*/

  /* NOT BEING USED IN ANY FUNCTIONAL VIEW ANYMORE
	getTasksStatus(compId) {
	  return this.http.get(AppComponent.API_URL + '/report/status/company/' + compId, { headers: this.getOptions() });
	}*/

  getTaskDescription(compId) {
    return this.http.get(
      AppComponent.API_URL + "/record/status/company/" + compId,
      { headers: this.getOptions() }
    );
  }

  getTasksUserGantt(id, compId) {
    return this.http.get(
      AppComponent.API_URL + "/report/tasks/" + id + "/company/" + compId,
      { headers: this.getOptions() }
    );
  }

  getTasksStatusFilter(filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL + "/report/tasksStatusFilter/",
      body,
      { headers: this.getOptions() }
    );
  }

  getTasksFilter(filter) {
    let body = JSON.stringify(filter);
    return this.http.post(AppComponent.API_URL + "/report/tasksfilter/", body, {
      headers: this.getOptions(),
    });
  }

  /* not being used in any functional view anymore
	getTasksReportFilter(filter) {
	  let body = JSON.stringify(filter);
	  return this.http.post(AppComponent.API_URL + '/report/tasksReportFilter/', body, { headers: this.getOptions() });
	}*/

  getAppointmentsReportFilter(page, size, filter, pageRemainder, initSupport) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL +
        "/report/appointmentsReportFilter/" +
        pageRemainder +
        "/" +
        initSupport +
        "?page=" +
        page +
        "&size=" +
        size,
      body,
      { headers: this.getOptions() }
    );
  }

  appointmentsReport(filter, kindOfReport, type) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL +
        "/report/appointmentsJasper/" +
        kindOfReport +
        "/" +
        type +
        "/",
      body,
      { responseType: "blob", headers: this.getOptions() }
    );
  }

  getAppointmentsTicket(page, size, filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL +
        "/timetrackingtickets/analytic?page=" +
        page +
        "&size=" +
        size,
      body,
      { headers: this.getOptions() }
    );
  }

  //Melhora esse cara no front e no back e elimina as coisas desnecessarias,
  getTimeSheetFilter(page, size, filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL +
        "/timetrackingtickets/find/filter?page=" +
        page +
        "&size=" +
        size,
      body,
      { headers: this.getOptions() }
    );
  }

  getAppointmentsProject(page, size, filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL +
        "/timetracking/analytic?page=" +
        page +
        "&size=" +
        size,
      body,
      { headers: this.getOptions() }
    );
  }

  /* not being used in any functional view anymore
	getAppointmentsReportFilterTotalTime(filter) {
	  let body = JSON.stringify(filter);
	  return this.http.post(AppComponent.API_URL + '/report/appointmentsReportFilterTotalTime/', body, { headers: this.getOptions() });
	}*/

  /* not being used in any functional view anymore
	getReportAppointmentsFilterTime(filter) {
	  let body = JSON.stringify(filter);
	  return this.http.post(AppComponent.API_URL + '/report/appointmentsReportFilterTime/', body, { headers: this.getOptions() });
	}*/

  getAppointmentsTotalTimeByFilter(filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL + "/report/appointmentsTotalTimeFilter",
      body,
      { headers: this.getOptions() }
    );
  }

  getAppointmentsTotalTimeTicket(filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL + "/report/appointmentsTotalTimeTicket",
      body,
      { headers: this.getOptions() }
    );
  }

  getAppointmentsTotalTime(filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL + "/report/appointmentsTotalTime",
      body,
      { headers: this.getOptions() }
    );
  }

  getOrderByItems() {
    return this.http.get(AppComponent.API_URL + "/timetracking/orderByItems/", {
      headers: this.getOptions(),
    });
  }

  getCostCenters() {
    return this.http.get(AppComponent.API_URL + "/costcenter/costcenters", {
      headers: this.getOptions(),
    });
  }

  getCostCentersByCompId(compId) {
    return this.http.get(
      AppComponent.API_URL + "/costcenter/company/" + compId,
      { headers: this.getOptions() }
    );
  }

  updateCC(cc, id) {
    let body = JSON.stringify(cc);
    return this.http.put(
      AppComponent.API_URL + "/costcenter/costcenters/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  createCC(cc) {
    let body = JSON.stringify(cc);
    return this.http.post(
      AppComponent.API_URL + "/costcenter/costcenters/",
      body,
      { headers: this.getOptions() }
    );
  }

  deleteCC(cc) {
    return this.http.delete(
      AppComponent.API_URL + "/costcenter/costcenters/" + cc.id,
      { headers: this.getOptions() }
    );
  }

  /** End CostCenter */

  /** View */
  getResourceView(compId) {
    return this.http.get(
      AppComponent.API_URL + "/view/resources/company/" + compId,
      { headers: this.getOptions() }
    );
  }

  getTaskViews(userId, compId) {
    return this.http.get(
      AppComponent.API_URL +
        "/view/tasks/company/" +
        compId +
        "/user/" +
        userId,
      { headers: this.getOptions() }
    );
  }

  getTaskIdViews(userId, compId, taskId) {
    return this.http.get(
      AppComponent.API_URL +
        "/view/tasks/company/" +
        compId +
        "/user/" +
        userId +
        "/task/" +
        taskId,
      { headers: this.getOptions() }
    );
  }

  getTaskUsers(userId) {
    return this.http.get(AppComponent.API_URL + "/view/tasks/user/" + userId, {
      headers: this.getOptions(),
    });
  }

  getTaskDep(depaId) {
    return this.http.get(
      AppComponent.API_URL + "/view/tasks/departament/" + depaId,
      { headers: this.getOptions() }
    );
  }

  getTaskCust(custId) {
    return this.http.get(
      AppComponent.API_URL + "/view/tasks/customer/" + custId,
      { headers: this.getOptions() }
    );
  }

  getTaskViewsTaskName(userId, compId, taskName) {
    if (taskName == undefined || taskName == "") {
      return this.http.get(
        AppComponent.API_URL +
          "/view/tasks/company/" +
          compId +
          "/user/" +
          userId,
        { headers: this.getOptions() }
      );
    } else {
      return this.http.get(
        AppComponent.API_URL +
          "/view/tasks/company/" +
          compId +
          "/user/" +
          userId +
          "/taskName/" +
          taskName,
        { headers: this.getOptions() }
      );
    }
  }

  getProjectViews(userId, compId) {
    return this.http.get(
      AppComponent.API_URL +
        "/view/projects/company/" +
        compId +
        "/user/" +
        userId,
      { headers: this.getOptions() }
    );
  }

  getProjectViewsProject(userId, compId, projectId) {
    return this.http.get(
      AppComponent.API_URL +
        "/view/projects/company/" +
        compId +
        "/user/" +
        userId +
        "/project/" +
        projectId,
      { headers: this.getOptions() }
    );
  }

  getProjectViewsProjectName(userId, compId, projectName) {
    if (projectName == undefined || projectName == "") {
      return this.http.get(
        AppComponent.API_URL +
          "/view/projects/company/" +
          compId +
          "/user/" +
          userId,
        { headers: this.getOptions() }
      );
    } else {
      return this.http.get(
        AppComponent.API_URL +
          "/view/projects/company/" +
          compId +
          "/user/" +
          userId +
          "/projectName/" +
          projectName,
        { headers: this.getOptions() }
      );
    }
  }
  /**
   * Priority
   */
  getPrioritiesByCompId(compId) {
    return this.http.get(AppComponent.API_URL + "/priority/company/" + compId, {
      headers: this.getOptions(),
    });
  }

  getPriorities() {
    return this.http.get(AppComponent.API_URL + "/priority/priorities", {
      headers: this.getOptions(),
    });
  }

  getPrioritiesById(id) {
    return this.http.get(AppComponent.API_URL + "/priority/priorities/" + id, {
      headers: this.getOptions(),
    });
  }

  updatePriority(priority, id) {
    let body = JSON.stringify(priority);
    return this.http.put(
      AppComponent.API_URL + "/priority/priorities/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  createPriority(priority) {
    let body = JSON.stringify(priority);
    return this.http.post(
      AppComponent.API_URL + "/priority/priorities/",
      body,
      { headers: this.getOptions() }
    );
  }

  deletePriority(priority) {
    return this.http.delete(
      AppComponent.API_URL + "/priority/priorities/" + priority.id,
      { headers: this.getOptions() }
    );
  }

  /** End Priority */

  /** Status */

  getStatusByCompId(compId) {
    return this.http.get(AppComponent.API_URL + "/status/company/" + compId, {
      headers: this.getOptions(),
    });
  }

  getStatusByParameters(compId, paraId) {
    return this.http.get(
      AppComponent.API_URL +
        "/status/company/" +
        compId +
        "/parameter/" +
        paraId,
      { headers: this.getOptions() }
    );
  }

  getStatusById(id) {
    return this.http.get(AppComponent.API_URL + "/status/statusId/" + id, {
      headers: this.getOptions(),
    });
  }

  getCountStatusByParameters(compId, paraId) {
    return this.http.get(
      AppComponent.API_URL +
        "/status/company/" +
        compId +
        "/countParameter/" +
        paraId,
      { headers: this.getOptions() }
    );
  }

  getStatus() {
    return this.http.get(AppComponent.API_URL + "/status/status", {
      headers: this.getOptions(),
    });
  }

  updateStatus(status, id) {
    let body = JSON.stringify(status);
    return this.http.put(AppComponent.API_URL + "/status/status/" + id, body, {
      headers: this.getOptions(),
    });
  }

  createStatus(status) {
    let body = JSON.stringify(status);
    return this.http.post(AppComponent.API_URL + "/status/status/", body, {
      headers: this.getOptions(),
    });
  }

  deleteStatus(status) {
    return this.http.delete(
      AppComponent.API_URL + "/status/status/" + status.id,
      { headers: this.getOptions() }
    );
  }
  /** End Status */
  /** Analytic */
  getTeamName(compId) {
    return this.http.get(
      AppComponent.API_URL + "/analytic/status/company/" + compId,
      { headers: this.getOptions() }
    );
  }
  getTasksAnalytic(compId) {
    return this.http.get(
      AppComponent.API_URL + "/analytic/tasks/company/" + compId,
      { headers: this.getOptions() }
    );
  }

  /*
	getTasksAnalyticFilter(filter) {
	  let body = JSON.stringify(filter);
	  return this.http.post(AppComponent.API_URL + '/analytic/tasksFilter', body, { headers: this.getOptions() });
  
	}*/

  /**Resource**/
  /* not being used in any functional view anymore
	getResourceUsage(compId) {
	  return this.http.get(AppComponent.API_URL + '/report/allocations/usage/' + compId, { headers: this.getOptions() });
	}*/

  getResourceUsageFilter(filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL + "/report/allocations/usage/resourceUsageFilter",
      body,
      { headers: this.getOptions() }
    );
  }

  getTaskResponsibleFilterStart(filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL + "/report/tasks/usage/taskUsageFilterStart",
      body,
      { headers: this.getOptions() }
    );
  }

  getTaskResponsibleFilterStartUserAndStatus(filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL +
        "/report/tasks/usage/taskUsageFilterStartUserAndStatus",
      body,
      { headers: this.getOptions() }
    );
  }

  /* not being used in any functional view anymore
	getTaskResponsibleFilterStartUser(filter) {
	  let body = JSON.stringify(filter);
	  return this.http.post(AppComponent.API_URL + '/report/tasks/usage/taskUsageFilterStartUser', body, { headers: this.getOptions() });
	}
  
	getTaskResponsibleFilterStartStatus(filter) {
	  let body = JSON.stringify(filter);
	  return this.http.post(AppComponent.API_URL + '/report/tasks/usage/taskUsageFilterStartStatus', body, { headers: this.getOptions() });
	}
  
	getTaskResponsibleFilterEnd(filter) {
	  let body = JSON.stringify(filter);
	  return this.http.post(AppComponent.API_URL + '/report/tasks/usage/taskUsageFilterEnd', body, { headers: this.getOptions() });
	}
  
	getTaskResponsibleFilterEndUserAndStatus(filter) {
	  let body = JSON.stringify(filter);
	  return this.http.post(AppComponent.API_URL + '/report/tasks/usage/taskUsageFilterEndUserAndStatus', body, { headers: this.getOptions() });
	}
  
	getTaskResponsibleFilterEndUser(filter) {
	  let body = JSON.stringify(filter);
	  return this.http.post(AppComponent.API_URL + '/report/tasks/usage/taskUsageFilterEndUser', body, { headers: this.getOptions() });
	}
  
	getTaskResponsibleFilterEndStatus(filter) {
	  let body = JSON.stringify(filter);
	  return this.http.post(AppComponent.API_URL + '/report/tasks/usage/taskUsageFilterEndStatus', body, { headers: this.getOptions() });
	}*/

  getTaskResponsibleFilter(filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL + "/report/tasks/usage/taskUsageFilter",
      body,
      { headers: this.getOptions() }
    );
  }

  getTaskResponsibleFilterUserAndStatus(filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL + "/report/tasks/usage/taskUsageFilterUserAndStatus",
      body,
      { headers: this.getOptions() }
    );
  }

  getTaskResponsibleFilterByUser(filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL + "/report/tasks/usage/taskUsageFilterUserByUser",
      body,
      { headers: this.getOptions() }
    );
  }

  getTaskResponsibleFilterStatus(filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL + "/report/tasks/usage/taskUsageFilterStatus",
      body,
      { headers: this.getOptions() }
    );
  }

  /* not being used in any functional view anymore
	getTaskByOperationFilter(filter) {
	  let body = JSON.stringify(filter);
	  return this.http.post(AppComponent.API_URL + '/report/tasks/usage/taskByOperationFilter', body, { headers: this.getOptions() });
	}
  
	getTaskByComplexityFilter(filter) {
	  let body = JSON.stringify(filter);
	  return this.http.post(AppComponent.API_URL + '/report/tasks/usage/taskByComplexityFilter', body, { headers: this.getOptions() });
	}
  
	getResourceUsageByProjects(compId) {
	  return this.http.get(AppComponent.API_URL + '/report/allocations/usage/' + compId + '/projects', { headers: this.getOptions() });
	}*/

  getTimeCapacity(filter) {
    let body = JSON.stringify(filter);
    return this.http.post(
      AppComponent.API_URL + "/report/time/usage/timesheet",
      body,
      { headers: this.getOptions() }
    );
  }

  getCalendars() {
    return this.http.get(AppComponent.API_URL + "/calendar/calendars", {
      headers: this.getOptions(),
    });
  }

  getCalendarsForCompId(compId) {
    return this.http.get(AppComponent.API_URL + "/calendar/company/" + compId, {
      headers: this.getOptions(),
    });
  }

  getCalendarsResourceForCompId(compId, resourceId, absenceId) {
    if (!resourceId || resourceId == 0) {
      return this.http.get(
        AppComponent.API_URL + "/calendar/company/" + compId,
        { headers: this.getOptions() }
      );
    } else {
      return this.http.get(
        AppComponent.API_URL +
          "/calendar/company/" +
          compId +
          "/resource/" +
          resourceId +
          "/absence/" +
          absenceId,
        { headers: this.getOptions() }
      );
    }
  }

  getCalendarsResourceForComp(compId, absenceId) {
    return this.http.get(
      AppComponent.API_URL +
        "/calendar/company/" +
        compId +
        "/absence/" +
        absenceId,
      { headers: this.getOptions() }
    );
  }

  createCalendar(calendar) {
    let body = JSON.stringify(calendar);
    return this.http.post(AppComponent.API_URL + "/calendar/", body, {
      headers: this.getOptions(),
    });
  }

  createCalendarForAll(calendar) {
    let body = JSON.stringify(calendar);
    return this.http.post(AppComponent.API_URL + "/calendar/", body, {
      headers: this.getOptions(),
    });
  }

  updateCalendar(calendar, id) {
    let body = JSON.stringify(calendar);
    return this.http.put(AppComponent.API_URL + "/calendar/" + id, body, {
      headers: this.getOptions(),
    });
  }

  deleteCalendar(calendar) {
    return this.http.delete(AppComponent.API_URL + "/calendar/" + calendar.id, {
      headers: this.getOptions(),
    });
  }

  //Workflow*/
  getWorkflow() {
    return this.http.get(AppComponent.API_URL + "/workflow/workflows/", {
      headers: this.getOptions(),
    });
  }
  getWorkflowByComp(compId) {
    return this.http.get(AppComponent.API_URL + "/workflow/company/" + compId, {
      headers: this.getOptions(),
    });
  }
  getWorkflowByWork(workId) {
    return this.http.get(
      AppComponent.API_URL + "/workflow/workflows/" + workId,
      { headers: this.getOptions() }
    );
  }
  createWorkflow(work) {
    let body = JSON.stringify(work);
    return this.http.post(AppComponent.API_URL + "/workflow/workflows/", body, {
      headers: this.getOptions(),
    });
  }
  updateWorkflow(work, id) {
    let body = JSON.stringify(work);
    return this.http.put(
      AppComponent.API_URL + "/workflow/workflows/" + id,
      body,
      { headers: this.getOptions() }
    );
  }
  deleteWorkflow(workId) {
    return this.http.delete(
      AppComponent.API_URL + "/workflow/workflows/" + workId,
      { headers: this.getOptions() }
    );
  }

  /*Absence*/
  getAbsencesByCompId(compId) {
    return this.http.get(AppComponent.API_URL + "/absences/company/" + compId, {
      headers: this.getOptions(),
    });
  }

  getAbsences() {
    return this.http.get(AppComponent.API_URL + "/absences/absences", {
      headers: this.getOptions(),
    });
  }

  getAbsencesById(id) {
    return this.http.get(AppComponent.API_URL + "/absences/absences/" + id, {
      headers: this.getOptions(),
    });
  }

  updateAbsence(absence, id) {
    let body = JSON.stringify(absence);
    return this.http.put(
      AppComponent.API_URL + "/absences/absences/" + id,
      body,
      { headers: this.getOptions() }
    );
  }

  createAbsence(absence) {
    let body = JSON.stringify(absence);
    return this.http.post(AppComponent.API_URL + "/absences/absences/", body, {
      headers: this.getOptions(),
    });
  }

  deleteAbsence(absence) {
    return this.http.delete(
      AppComponent.API_URL + "/absences/absences/" + absence.id,
      { headers: this.getOptions() }
    );
  }

  // uploadFile(data: FormData) {
  //   let headers = new HttpHeaders()
  //     .set('Access-Control-Allow-Origin', '*')
  //     .set('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS')
  //     .set('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token')
  //     .set('Accept', '*/*')
  //     .set('Authorization', 'Basic ' + sessionStorage.getItem('base64Credential'))
  //     .set('enctype', 'multipart/form-data');
  //   return this.http.post(AppComponent.API_URL + '/s3/upload', data,
  //     { headers: headers });
  // }

  // downloadFile(ta) {
  //   return this.http.post(AppComponent.API_URL + '/s3/download', ta, { headers: this.getOptions(), responseType: 'blob', observe: 'body' })
  // }

  // deleteFile(ta) {
  //   return this.http.post(AppComponent.API_URL + '/s3/delete', ta, { headers: this.getOptions() })
  // }

  uploadFile(data: FormData) {
    console.log("Upload files");
    let headers = new HttpHeaders()
      .set("Access-Control-Allow-Origin", "*")
      .set(
        "Access-Control-Allow-Methods",
        "GET, POST, PATCH, PUT, DELETE, OPTIONS"
      )
      .set("Access-Control-Allow-Headers", "Origin, Content-Type, X-Auth-Token")
      .set("Accept", "*/*")
      .set(
        "Authorization",
        "Basic " + sessionStorage.getItem("base64Credential")
      )
      .set("enctype", "multipart/form-data");
    return this.http.post(AppComponent.API_URL + "/S3AWS/upload", data, {
      headers: headers,
    });
  }

  getFiles(tableName, tableId) {
    return this.http.get(
      AppComponent.API_URL + "/S3AWS/" + tableName + "/" + tableId,
      { headers: this.getOptions() }
    );
  }

  downloadFile(data) {
    let body = JSON.stringify(data);
    return this.http.post(AppComponent.API_URL + "/S3AWS/download", body, {
      headers: this.getOptions(),
      responseType: "blob",
      observe: "body",
    });
  }

  downloadTicketUserAttachments(ticketId) {
    return this.http.get(
      AppComponent.API_URL + `/ticket/${ticketId}/user-attachments-download`,
      { headers: this.getOptions(), responseType: "blob", observe: "body" }
    );
  }

  deleteFile(data) {
    return this.http.delete(AppComponent.API_URL + "/S3AWS/" + data.id, {
      headers: this.getOptions(),
    });
  }

  redirectToProgressByUsage(idProject, idCustomer) {
    let url: string = "/report/progress-reports";
    let queryParams: NavigationExtras = {
      queryParams: { idProject: idProject, idCustomer: idCustomer },
    };
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([url], queryParams);
  }

  redirectToTimeTrackingAnalytic(idProject, idCustomer, idTask) {
    let url: string = "/report/time-tracking-analytic";
    let queryParams: NavigationExtras = {
      queryParams: {
        idProject: idProject,
        idCustomer: idCustomer,
        idTask: idTask,
      },
    };
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([url], queryParams);
  }

  getCostType() {
    return this.http.get(AppComponent.API_URL + "/costType/findByName/COST");
  }

  getBillingType() {
    return this.http.get(AppComponent.API_URL + "/costType/findByName/BILLING");
  }

  getCostByProject(projectId) {
    return this.http.get(
      AppComponent.API_URL + "/projectCost/findByProject/" + projectId + "/COST"
    );
  }

  getBillingByProject(projectId) {
    return this.http.get(
      AppComponent.API_URL +
        "/projectCost/findByProject/" +
        projectId +
        "/BILLING"
    );
  }

  getProgressBilling(projectId) {
    return this.http.get(
      AppComponent.API_URL +
        "/projectCost/findByProject/progressBarAsCost/" +
        projectId +
        "/BILLING"
    );
  }

  getProgressCost(projectId) {
    return this.http.get(
      AppComponent.API_URL +
        "/projectCost/findByProject/progressBarAsCost/" +
        projectId +
        "/COST"
    );
  }
  getCostById(id) {
    return this.http.get(AppComponent.API_URL + "/projectCost/" + id);
  }
  createCost(creationObj: any) {
    return this.http.post(AppComponent.API_URL + "/projectCost/", creationObj);
  }
  updateCost(creationObj: any, id) {
    return this.http.put(
      AppComponent.API_URL + "/projectCost/" + id,
      creationObj
    );
  }
  deleteCost(id) {
    return this.http.delete(AppComponent.API_URL + "/projectCost/" + id);
  }
  createFollowUp(creationObj: any) {
    return this.http.post(AppComponent.API_URL + "/followUp/", creationObj);
  }
  updateFollowUp(creationObj: any, id) {
    return this.http.put(AppComponent.API_URL + "/followUp/" + id, creationObj);
  }
  deleteFollowUp(id) {
    return this.http.delete(AppComponent.API_URL + "/followUp/" + id);
  }
  getFollowUpByProject(projectId) {
    return this.http.get(
      AppComponent.API_URL + "/followUp/findByProject/" + projectId
    );
  }
  getFollowUpById(id) {
    return this.http.get(AppComponent.API_URL + "/followUp/" + id);
  }
  costReport(format, projectId) {
    return this.http.get(
      AppComponent.API_URL +
        "/projectCost/jasper/findByProject/" +
        format +
        "/" +
        projectId,
      { responseType: "blob", headers: this.getOptions() }
    );
  }
}
