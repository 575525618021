import { TimeSheetDialogComponent } from './../../views/dialogs/timesheet-dialog/timesheet-dialog.component';
import { Component, OnInit } from '@angular/core';
import { User } from '../../model/model.user';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { TimeTrackingService } from '../../services/time-tracking.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskDialogComponent } from '../../views/dialogs/task-dialog/task-dialog.component';
import { TicketDialogComponent } from '../../views/dialogs/ticket-dialog/ticket-dialog.component';
import { ProjectDialogComponent } from '../../views/dialogs/project-dialog/project-dialog.component';
import { UserChangePasswordComponent } from '../../views/dialogs/user-dialog/userchangepassword-dialog.component';

@Component({
	selector: 'app-dashboard',
	templateUrl: './full-layout.component.html'
})
export class FullLayoutComponent implements OnInit {
	public tasks;
	public taskviews;
	public users;
	currentUser: User;
	logo: string;
	isEnabledLogin: boolean = false;
	isEnabledMaster: boolean = false;
	isEnabledAdmin: boolean = false;
	isEnabledUser: boolean = false;
	isEnabledManager: boolean = false;
	isEnabledAnalyst: boolean = false;
	KN: boolean = false;
	Beta: boolean = false;
	nameUser: string;
	compName: string;
	userId: string;
	taskTitle: string;
	alertsDismiss: any = [];
	opened: any = [];
	delayed: any = [];
	deadline: any = [];
	closed: any = [];
	user: User;

	constructor(public authService: AuthService, public router: Router, private ngbModal: NgbModal, private ttService: TimeTrackingService) {
		this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
		this.isEnabledLogin = false;
		this.isEnabledAdmin = false;
		this.isEnabledUser = false;
		this.isEnabledAnalyst = false;
		this.isEnabledManager = false;

		if (this.currentUser != null) {
			this.nameUser = this.currentUser.fullName;

			if (this.currentUser.profile == 'MASTER') {
				this.isEnabledMaster = true;
			}
			else if (this.currentUser.profile == 'ADMIN') {
				this.isEnabledAdmin = true;
			}
			else if (this.currentUser.profile == 'ANALYST') {
				this.isEnabledAnalyst = true;
			}
			else if (this.currentUser.profile == 'USER') {
				this.isEnabledUser = true;
			}
			else if (this.currentUser.profile == 'MANAGER') {
				this.isEnabledManager = true;
			}

			this.isEnabledLogin = true;

			if (this.currentUser.compName == 'KN') {
				this.KN = true;
			}
			else if (this.currentUser.compName == 'BetaIT') {
				this.Beta = true;
			}
		}
	}

	ngOnInit() {
		this.getTasksUser();
		this.getTaskViews();

		this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
		this.logo = sessionStorage.getItem('compName');
	}

	showTask() {
		let modalDialog = this.ngbModal.open(TaskDialogComponent, { size: 'lg', backdrop: 'static', keyboard: false });
	}

	showTicket() {
		let modalDialog = this.ngbModal.open(TicketDialogComponent, { size: 'lg', backdrop: 'static', keyboard: false });
	}

	showTs() {
		let modalDialog = this.ngbModal.open(TimeSheetDialogComponent, { windowClass: 'sm-modal', backdrop: 'static', keyboard: false });
		modalDialog.componentInstance.disableEdit = false;
	}

	showProj() {
		let modalDialog = this.ngbModal.open(ProjectDialogComponent, { size: 'lg' });
	}

	logout() {
		sessionStorage.clear();
		this.router.navigateByUrl('/login');
		location.reload();
	}

	resetPassword() {
		this.ngbModal.open(UserChangePasswordComponent, { backdrop: 'static', keyboard: false });
	}

	getTasksUser() {
		var userId = sessionStorage.getItem('userId');
		var userProfile = sessionStorage.getItem('userProfile');

		if (userProfile != undefined && (userProfile == 'USER' || userProfile == 'ANALYST')) {
			this.ttService.getTasksForUserDash(userId).subscribe(
				// the first argument is a function which runs on success
				data => { this.tasks = data },
				// the second argument is a function which runs on error
				err => {
					this.ttService.checkErr(err, 'error loading tasks user', null);
					this.alertsDismiss.push({
						type: 'danger',
						msg: err.error.errorMessage,
						timeout: 5000
					});
				},
				// the third argument is a function which runs on completion
				() => console.log('done loading tasks user')
			);
		}
		else {
			let filter = {
				'company': { 'id': sessionStorage.getItem('compId') }
			};
			this.ttService.getTasksSituation(filter).subscribe(
				// the first argument is a function which runs on success
				data => { this.tasks = data },
				// the second argument is a function which runs on error
				err => {
					this.ttService.checkErr(err, 'error loading tasks', null);
					this.alertsDismiss.push({
						type: 'danger',
						msg: err.error.errorMessage,
						timeout: 5000
					});
				},
				// the third argument is a function which runs on completion
				() => console.log('done loading tasks')
			);
		}
	}

	getTaskViews() {
		this.userId = '0';
		if (this.user && this.user.profile != undefined && (this.user.profile == 'USER' || this.user.profile == 'ANALYST')) {
			this.userId = sessionStorage.getItem('userId');
		}

		this.ttService.getTaskViews(this.userId, sessionStorage.getItem('compId')).subscribe(
			data => {
				this.taskviews = data;
				this.opened = this.taskviews.opened;
				this.delayed = this.taskviews.delayed;
				this.deadline = this.taskviews.deadline;
				this.closed = this.taskviews.closed;
			},
			// the second argument is a function which runs on error
			err => {
				this.ttService.checkErr(err, 'error loading tasks user', null);
				this.alertsDismiss.push({
					type: 'danger',
					msg: err.error.errorMessage,
					timeout: 5000
				});

			},
			// the third argument is a function which runs on completion
			() => console.log('done loading tasks user')
		);
	}

	getTaskIdViews(taskId) {
		this.userId = '0';

		if (this.user.profile != undefined && (this.user.profile == 'USER' || this.user.profile == 'ANALYST')) {
			this.userId = sessionStorage.getItem('userId');
		}

		this.opened = 0;
		this.delayed = 0;
		this.deadline = 0;
		this.closed = 0;
		this.taskTitle = '';
		this.users = [];

		this.ttService.getTaskIdViews(this.userId, sessionStorage.getItem('compId'), taskId).subscribe(
			data => {
				this.taskviews = data;
				this.opened = this.taskviews.opened;
				this.delayed = this.taskviews.delayed;
				this.deadline = this.taskviews.deadline;
				this.closed = this.taskviews.closed;
				this.users = this.taskviews.users;
			},
			// the second argument is a function which runs on error
			err => {
				this.ttService.checkErr(err, 'error loading tasks user', null);
				this.alertsDismiss.push({
					type: 'danger',
					msg: err.error.errorMessage,
					timeout: 5000
				});

			},
			// the third argument is a function which runs on completion
			() => console.log('done loading tasks user')
		);
	}

	getTaskFilterDeadline() {
		sessionStorage.setItem('dash', this.deadline);
	}
}
