import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CostDialogComponent } from '../cost-dialog/cost-dialog.component';
import { BillingDialogComponent } from '../billing-dialog/billing-dialog.component';
import { TimeTrackingService } from '../../../services/time-tracking.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-project-cost-dialog',
  templateUrl: './project-cost-dialog.component.html',
  styleUrls: ['./project-cost-dialog.component.scss']
})
export class ProjectCostDialogComponent implements OnInit {

  constructor(private ngbModal: NgbModal, private ttService: TimeTrackingService) { }

  projectCols = [
    // { field: 'id',     header: 'History ID' },
    { field: 'invoice', header: 'Invoice' },
    { field: 'costTypeDescription', header: 'Type' },
    { field: 'lastUpdated', header: 'Date' },
    { field: 'description', header: 'Description' },
    { field: 'value', header: 'Value' },
  ];

  @Input() projectId;
  @Output() close: EventEmitter<any> = new EventEmitter();
  
  costData;
  billingData;

  progressCost;
  progressBilling;
  signalCost;

  busy: Subscription;

  ngOnInit() {
    this.getCostByProject();
    this.getBillingByProject();
    this.getProgressBilling();
    this.getProgressCost();
  }

  openDialog(dialog) {
    if (dialog === 'cost') {
      const modalDialog = this.ngbModal.open(CostDialogComponent, { backdrop: 'static', keyboard: false });
      modalDialog.componentInstance.projectId = this.projectId;
      modalDialog.componentInstance.tree = false;
      modalDialog.result.then(() => { this.getCostByProject(); this.getProgressCost(); });
    } else {
      const modalDialog = this.ngbModal.open(BillingDialogComponent, { backdrop: 'static', keyboard: false });
      modalDialog.componentInstance.projectId = this.projectId;
      modalDialog.componentInstance.tree = false;
      modalDialog.result.then(() => { this.getBillingByProject(); this.getProgressBilling(); });
    }
  }

  openDialogUpdate(dialog, id) {
    if (dialog === 'cost') {
      const modalDialog = this.ngbModal.open(CostDialogComponent, { backdrop: 'static', keyboard: false });
      modalDialog.componentInstance.projectId = this.projectId;
      modalDialog.componentInstance.id = id;
      modalDialog.componentInstance.tree = false;
      modalDialog.result.then(() => {
        this.getCostByProject();
        this.getProgressCost();
      });
    } else {
      const modalDialog = this.ngbModal.open(BillingDialogComponent, { backdrop: 'static', keyboard: false });
      modalDialog.componentInstance.projectId = this.projectId;
      modalDialog.componentInstance.id = id;
      modalDialog.componentInstance.tree = false;
      modalDialog.result.then(() => {
        this.getBillingByProject();
        this.getProgressBilling();
      });
    }
  }

  getProgressBilling() {
    this.ttService.getProgressBilling(this.projectId).subscribe(data => {
      this.progressBilling = data['progressBar'];
    });
  }

  getProgressCost() {
    this.ttService.getProgressCost(this.projectId).subscribe(data => {
      this.progressCost = data;
      this.signalCost = this.progressCost.signal;
    });
  }

  getCostByProject() {
    this.ttService.getCostByProject(this.projectId).subscribe(data => {
      this.costData = data;
      for (const cost of this.costData) {
        const date = new Date();
        date.setTime(cost.lastUpdated);
        // tslint:disable-next-line: max-line-length
        cost.lastUpdated = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
      }
    });
  }

  getBillingByProject() {
    this.ttService.getBillingByProject(this.projectId).subscribe(data => {
      this.billingData = data;
      for (const cost of this.billingData) {
        const date = new Date();
        date.setTime(cost.lastUpdated);
        // tslint:disable-next-line: max-line-length
        cost.lastUpdated = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
      }
    });
  }

  export(format) {
    this.busy = this.ttService.costReport(format, this.projectId).subscribe(
      data => {
        let object;
        const fileExtension = format;
        const reportName = 'ProjectCost';

        if (fileExtension === 'pdf') {
          object = new Blob([data], { type: 'application/pdf' });
        } else if (fileExtension === 'xls') {
          object = new Blob([data], { type: 'application/vnd.ms-excel' });
        }

        const objectUrl = window.URL.createObjectURL(object);

        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = reportName + '.' + fileExtension;

        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(objectUrl);
          link.remove();
        }, 100);
      },
      err => {
        console.log(err);
      },
      () => { console.log('Done loading Time Trackings.'); });
  }

}
