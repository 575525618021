import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { Input, ViewChild } from '@angular/core';
import { TimeTrackingService } from './../../../services/time-tracking.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {NgxMaskModule} from 'ngx-mask';
import "bootbox";
import { SystemComponent } from '../../configuration/system.component';
import { PaginatorModule } from 'primeng/paginator';
import { async } from '@angular/core/testing';

@Component({
  selector: 'sla-dialog',
  templateUrl: './sla-dialog.component.html'
})
export class SlaDialogComponent implements OnInit {
  @ViewChild(Table) dt: Table;
  @Input() systemId:      number;
  @Input() type:          string;
  
  public companyId = sessionStorage.getItem('compId');
  public systems;
  public mask = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

  newVersionModalRef:     NgbModalRef;
  busy:                   Subscription;
  
  slaId:                  number;
  totalPages:             number;
  totalElements:          number;

  titleHeader:            string;
  logTitle:               string;
  slhiDescription:        string;

  showSlaHistory:         boolean;
  buttonAction:           boolean;
  systemDisabled:         boolean = false;
  

  sla:                    any;

  slas:                   any[] = new Array();
  slhiCols:               any;
  systemSelected:         any;
  firstResponse1:         any;
  firstResponse2:         any;
  firstResponse3:         any;
  firstResponse4:         any;
  timeToConcluse1:        any;
  timeToConcluse2:        any;
  timeToConcluse3:        any;
  timeToConcluse4:        any;
  event:                  any;
  eventComment:           any;

  alertsDismiss:          any[] = [];
  logDescription:         any[] = [];
  slaHistories:           any[] = [];
  
  historyDate:            Date;
  constructor(private ttService: TimeTrackingService, private ngbModal: NgbModal, private modalRef: NgbActiveModal){}
  
  ngOnInit() {

    this.titleHeader = 'New SLA'; 
    
    this.buttonAction = false;
    this.showSlaHistory = false;
    
    this.alertsDismiss = [];
    this.slhiDescription = '';
    this.systemSelected = '';
    this.firstResponse1 = '';
    this.firstResponse2 = '';
    this.firstResponse3 = '';
    this.firstResponse4 = '';
    this.timeToConcluse1 = '';
    this.timeToConcluse2 = '';
    this.timeToConcluse3 = '';
    this.timeToConcluse4 = '';

    this.getSystems();

    if (this.systemId) {
      this.systemDisabled = true;

      if(this.type == 'update'){
        this.buttonAction = true;
        this.showSlaHistory = true;        
        this.getSlasBySystem();    

      } else if(this.type == 'create'){
        this.fillSla();
      }

      this.slhiCols = [
        //{ field: 'id',     header: 'History ID' },
        { field: 'date',       header: 'Date' },
        { field: 'userName',   header: 'User' },
        { field: 'action', header: 'Action' },
        { field: 'description',   header: 'Description' }
       
      ];  
    }   
  }

  timeToDecimal(time){
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  }

  getSlasBySystem() {
    this.busy = this.ttService.getSlasBySystem(this.systemId).subscribe(
      data => {
        let unorderedSlas = data['slas'];
        this.slas = [];
        
        for(let i = 0; i < 5; i++){
          if(i != 4){
            for(let j = 0; j < 4; j++){
              if(i + 1 == unorderedSlas[j].prioritySla.index){
                this.slas.push(unorderedSlas[j]);
              }
            }
          }else{
            console.log(this.slas)
            this.fillSla();
          }
        }
      }
    );
  }

  getSystems(){
    if(this.type == 'create'){
    this.busy = this.ttService.getSystemsWithoutSla(this.companyId).subscribe(
      data =>{
        this.systems = data['systems'];
        console.log(this.systems)
      },
      err =>{
        console.log(err);
        this.ttService.checkErr(err,'error get Systems', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.errorerrorMessage,
          timeout: 5000
        });
      },
      () => console.log("Done loading Systems")
    )
    }else{
      this.busy = this.ttService.getSystems(this.companyId).subscribe(
        data =>{
          this.systems = data['systems'];
          console.log(this.systems);
        },
        err =>{
          this.ttService.checkErr(err,'error get Systems', this.modalRef);
          this.alertsDismiss.push({
            type: 'danger',
            msg: err.errorerrorMessage,
            timeout: 5000
          });
        },
        () => console.log("Done loading Systems")
      )
    }
  }

  compare(a, b) {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  }

  getSlaHistory(page, size) {
    let slaIds = this.slas[0].id 
     + "," + this.slas[1].id 
     + "," + this.slas[2].id 
     + "," + this.slas[3].id
    this.busy = this.ttService.getSlaHistory(slaIds, page, size).subscribe(
      data => {      
        let historyDate;
        this.slaHistories = data["slaLogs"];
        for(let history of this.slaHistories){
          history["userName"] = history.author.fullName;
  
          historyDate = new Date();
          historyDate.setTime(history.dateCreated);
          history["date"] = historyDate.getFullYear() + "-" + ("0" + (historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + historyDate.getDate()).slice(-2)+ " - " + ("0" + historyDate.getHours()).slice(-2) + ":" + ("0" + historyDate.getMinutes()).slice(-2);
        }

        this.totalPages = data["pageNumber"];
        this.totalElements = data["elementNumber"];
      }
    );
  }

fillSla() {
  this.systemSelected = this.systemId;
  console.log(this.systemId)
  
  if(this.type == 'update'){
    
    for(let sla of this.slas){
      //fill first response & time to concluse
      if(sla.prioritySla.id == 1){
        this.firstResponse1 = sla.firstResponse;
        this.timeToConcluse1 = sla.timeToConcluse;

      } else if(sla.prioritySla.id == 2){
        this.firstResponse2 = sla.firstResponse;
        this.timeToConcluse2 = sla.timeToConcluse;

      } else if(sla.prioritySla.id == 3){
        this.firstResponse3 = sla.firstResponse;
        this.timeToConcluse3 = sla.timeToConcluse;

      } else if(sla.prioritySla.id == 4){
        this.firstResponse4 = sla.firstResponse;
        this.timeToConcluse4 = sla.timeToConcluse;
      }
      this.titleHeader = 'View SLA #' + sla.system.name;
    }
    this.paginate(null);
  }
}

completeHour(index){
  if(index==0){
    if(this.firstResponse1.length==1){
      this.firstResponse1 = '0'+this.firstResponse1+'00'
    }else if(this.firstResponse1.length==2){
      this.firstResponse1 = this.firstResponse1+'00'
    }else if(this.firstResponse1.length==4){
      this.firstResponse1 = this.firstResponse1+'0'
    }
  }
  if(index==1){
    if(this.firstResponse2.length==1){
      this.firstResponse2 = '0'+this.firstResponse2+'00'
    }else if(this.firstResponse2.length==2){
      this.firstResponse2 = this.firstResponse2+'00'
    }else if(this.firstResponse2.length==4){
      this.firstResponse2 = this.firstResponse2+'0'
    }
  }
  if(index==2){
    if(this.firstResponse3.length==1){
      this.firstResponse3 = '0'+this.firstResponse3+'00'
    }else if(this.firstResponse3.length==2){
      this.firstResponse3 = this.firstResponse3+'00'
    }else if(this.firstResponse3.length==4){
      this.firstResponse3 = this.firstResponse3+'0'
    }
  }
  if(index==3){
    if(this.firstResponse4.length==1){
      this.firstResponse4 = '0'+this.firstResponse4+'00'
    }else if(this.firstResponse4.length==2){
      this.firstResponse4 = this.firstResponse4+'00'
    }else if(this.firstResponse4.length==4){
      this.firstResponse4 = this.firstResponse4+'0'
    }
  }
  if(index==4){
    if(this.timeToConcluse1.length==1){
      this.timeToConcluse1 = '0'+this.timeToConcluse1+'00'
    }else if(this.timeToConcluse1.length==2){
      this.timeToConcluse1 = this.timeToConcluse1+'00'
    }else if(this.timeToConcluse1.length==4){
      this.timeToConcluse1 = this.timeToConcluse1+'0'
    }
  }
  if(index==5){
    if(this.timeToConcluse2.length==1){
      this.timeToConcluse2 = '0'+this.timeToConcluse2+'00'
    }else if(this.timeToConcluse2.length==2){
      this.timeToConcluse2 = this.timeToConcluse2+'00'
    }else if(this.timeToConcluse2.length==4){
      this.timeToConcluse2 = this.timeToConcluse2+'0'
    }
  }
  if(index==6){
    if(this.timeToConcluse3.length==1){
      this.timeToConcluse3 = '0'+this.timeToConcluse3+'00'
    }else if(this.timeToConcluse3.length==2){
      this.timeToConcluse3 = this.timeToConcluse3+'00'
    }else if(this.timeToConcluse3.length==4){
      this.timeToConcluse3 = this.timeToConcluse3+'0'
    }
  }
  if(index==7){
    if(this.timeToConcluse4.length==1){
      this.timeToConcluse4 = '0'+this.timeToConcluse4+'00'
    }else if(this.timeToConcluse4.length==2){
      this.timeToConcluse4 = this.timeToConcluse4+'00'
    }else if(this.timeToConcluse4.length==4){
      this.timeToConcluse4 = this.timeToConcluse4+'0'
    }
  }
}

slaValidation(){
  let result = true;
  let firstResponses = new Array<number>(4);
  let timeToConcluses = new Array<number>(4);  

  firstResponses[0] = this.timeToDecimal(this.firstResponse1);
  firstResponses[1] = this.timeToDecimal(this.firstResponse2);
  firstResponses[2] = this.timeToDecimal(this.firstResponse3);
  firstResponses[3] = this.timeToDecimal(this.firstResponse4);

  timeToConcluses[0] = this.timeToDecimal(this.timeToConcluse1);
  timeToConcluses[1] = this.timeToDecimal(this.timeToConcluse2);
  timeToConcluses[2] = this.timeToDecimal(this.timeToConcluse3);
  timeToConcluses[3] = this.timeToDecimal(this.timeToConcluse4);

  for(let c=0; c<4; c++){
    if(!(timeToConcluses[c] > firstResponses[c])){
      result = false;
      alert('Resolution Time of priority '+ (c+1) +' must be greater than its Response Time.')      
    }
    if(c != 0 && !(firstResponses[c] > firstResponses[c-1])){
      result = false;
      alert('Response Time of priority '+ (c+1) +' must be greater than predecessor priorities.')      
    }
  }  
  return result;
}

saveSla(){
  //if(!this.systemId || this.systemId == 0) {
  if(this.slaValidation()){
    if(this.type == 'create'){
      this.createSla();
    } else {
      this.updateSla();
    }
  }    
}

createSla() {
  let firstResponse, timeToConcluse;
  for(let id = 1; id <= 4; id++){
    if(id == 1){
      firstResponse  = this.firstResponse1;
      timeToConcluse = this.timeToConcluse1;
    } else if(id == 2){
      firstResponse = this.firstResponse2;
      timeToConcluse = this.timeToConcluse2;
    } else if(id == 3){
      firstResponse = this.firstResponse3;
      timeToConcluse = this.timeToConcluse3;
    } else if(id == 4){
      firstResponse = this.firstResponse4
      timeToConcluse = this.timeToConcluse4;
    }

    this.busy = this.ttService.createSla(
      {
        'system'          : {'id' : this.systemSelected},
        'prioritySla'     : {'id' : id},
        'firstResponse'   : firstResponse,
        'timeToConcluse'  : timeToConcluse,
        'activated'       : true
      }
    ).subscribe(
      response => {
        this.slaId = response["id"];
        this.slhiDescription = 'Created SLA';
        this.createSlaHistory('INSERT', this.slaId);
        this.close();
      },
      err => {
        this.ttService.checkErr(err,'error create sla', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.errorerrorMessage,
          timeout: 5000
        });
    });
  }
  alert('SLA is saved!');
}

updateSla(){
  return new Promise((resolve, reject) => {
    const firstPromise = new Promise((resolve, reject) => {
      console.log("ttc1: " + this.timeToConcluse1)
      console.log("bd_ttc1: " + this.slas[0].timeToConcluse)
      console.log("fr1: " + this.firstResponse1)
      console.log("bd_fr1: " + this.slas[0].firstResponse)
      if(this.timeToConcluse1 != this.slas[0].timeToConcluse || this.firstResponse1 != this.slas[0].firstResponse){
        this.busy = this.ttService.updateSla(
          {
            'system'          : {'id' : this.systemId},
            'prioritySla'     : {'id' : 1},
            'firstResponse'   : this.firstResponse1,
            'timeToConcluse'  : this.timeToConcluse1,
            'activated'       : true
          },this.slas[0].id).subscribe(
            response => {
              console.log("1")
              this.slhiDescription = "";
    
              if(this.firstResponse1 != this.slas[0].firstResponse){
                this.slhiDescription += "  'Response Time (h)' changed from [" + this.slas[0].firstResponse + "] to [" + this.firstResponse1 + "] on priority 1";
              }
          
              if(this.timeToConcluse1 != this.slas[0].timeToConcluse){
                if(this.slhiDescription.substr(-1) != ',' && this.slhiDescription != ''){
                  this.slhiDescription += ',';
                }
                this.slhiDescription += "  'Resolution Time (h)' changed from [" + this.slas[0].timeToConcluse + "] to [" + this.timeToConcluse1 + "] on priority 1";
              }
    
              if (this.slhiDescription != "") {
                this.createSlaHistory('UPDATE', this.slas[0].id);
              }
              
            },
            err => {
              this.ttService.checkErr(err,'error update sla', this.modalRef);
              this.alertsDismiss.push({
                type: 'danger',
                msg: err.errorerrorMessage,
                timeout: 5000
              });
            },
            () => {
              resolve(true);
            }
          );
      }else{
        resolve(true);
      }
    }).then(() => {
      const secondPromise = new Promise((resolve, reject) => {
        console.log("ttc2: " + this.timeToConcluse2)
        console.log("bd_ttc2: " + this.slas[1].timeToConcluse)
        console.log("fr2: " + this.firstResponse2)
        console.log("bd_fr2: " + this.slas[1].firstResponse)
        if(this.timeToConcluse2 != this.slas[1].timeToConcluse || this.firstResponse2 != this.slas[1].firstResponse){
          this.busy = this.ttService.updateSla(
            {
              'system'          : {'id' : this.systemId},
              'prioritySla'     : {'id' : 2},
              'firstResponse'   : this.firstResponse2,
              'timeToConcluse'  : this.timeToConcluse2,
              'activated'       : true
            },this.slas[1].id).subscribe(
              response => {
                console.log(2);
                this.slhiDescription = "";
      
                if(this.firstResponse2 != this.slas[1].firstResponse){
                  this.slhiDescription += "  'Response Time (h)' changed from [" + this.slas[1].firstResponse + "] to [" + this.firstResponse2 + "] on priority 2";
                }
            
                if(this.timeToConcluse2 != this.slas[1].timeToConcluse){
                  if(this.slhiDescription.substr(-1) != ',' && this.slhiDescription != ''){
                    this.slhiDescription += ',';
                  }
                  this.slhiDescription += "  'Resolution Time (h)' changed from [" + this.slas[1].timeToConcluse + "] to [" + this.timeToConcluse2 + "] on priority 2";
                }
      
                if (this.slhiDescription != "") {
                  this.createSlaHistory('UPDATE', this.slas[1].id);
                }
              },
              err => {
                this.ttService.checkErr(err,'error update sla', this.modalRef);
                this.alertsDismiss.push({
                  type: 'danger',
                  msg: err.errorerrorMessage,
                  timeout: 5000
                });
              },
              () => resolve(true)
            );
        }else{
          resolve(true);
        }
      }).then(() => {
        const thirdPromise = new Promise((resolve, reject) => {
          console.log("ttc3: " + this.timeToConcluse3)
          console.log("bd_ttc3: " + this.slas[2].timeToConcluse)
          console.log("fr3: " + this.firstResponse3)
          console.log("bd_fr3: " + this.slas[2].firstResponse)
          if(this.timeToConcluse3 != this.slas[2].timeToConcluse || this.firstResponse3 != this.slas[2].firstResponse){
            this.busy = this.ttService.updateSla(
              {
                'system'          : {'id' : this.systemId},
                'prioritySla'     : {'id' : 3},
                'firstResponse'   : this.firstResponse3,
                'timeToConcluse'  : this.timeToConcluse3,
                'activated'       : true
              },this.slas[2].id).subscribe(
                response => {
                  console.log("3")
                  this.slhiDescription = "";
        
                  if(this.firstResponse3 != this.slas[2].firstResponse){
                    this.slhiDescription += "  'Response Time (h)' changed from [" + this.slas[2].firstResponse + "] to [" + this.firstResponse3 + "] on priority 3";
                  }
        
                  if(this.timeToConcluse3 != this.slas[2].timeToConcluse){
                    if(this.slhiDescription.substr(-1) != ',' && this.slhiDescription != ''){
                      this.slhiDescription += ',';
                    }
                    this.slhiDescription += "  'Resolution Time (h)' changed from [" + this.slas[2].timeToConcluse + "] to [" + this.timeToConcluse3 + "] on priority 3";
                  }
        
                  if (this.slhiDescription != "") {
                    this.createSlaHistory('UPDATE', this.slas[2].id);
                  }
                },
                err => {
                  this.ttService.checkErr(err,'error update sla', this.modalRef);
                  this.alertsDismiss.push({
                    type: 'danger',
                    msg: err.errorerrorMessage,
                    timeout: 5000
                  });
                },
                () => resolve(true)
              );
          }else{
            resolve(true);
          }
        }).then(() => {
          const fourthPromise = new Promise((resolve, reject) => {
            console.log("ttc4: " + this.timeToConcluse4)
            console.log("bd_ttc4: " + this.slas[3].timeToConcluse)
            console.log("fr4: " + this.firstResponse4)
            console.log("bd_fr4: " + this.slas[3].firstResponse)
            if(this.timeToConcluse4 != this.slas[3].timeToConcluse || this.firstResponse4 != this.slas[3].firstResponse){
              this.busy = this.ttService.updateSla(
                {
                  'system'          : {'id' : this.systemId},
                  'prioritySla'     : {'id' : 4},
                  'firstResponse'   : this.firstResponse4,
                  'timeToConcluse'  : this.timeToConcluse4,
                  'activated'       : true
                },this.slas[3].id).subscribe(
                  response => {
                    console.log("4")
                    this.slhiDescription = "";
      
                    if(this.firstResponse4 != this.slas[3].firstResponse){
                      this.slhiDescription += "  'Response Time (h)' changed from [" + this.slas[3].firstResponse + "] to [" + this.firstResponse4 + "] on priority 4";
                    }
      
                    if(this.timeToConcluse4 != this.slas[3].timeToConcluse){
                      if(this.slhiDescription.substr(-1) != ',' && this.slhiDescription != ''){
                        this.slhiDescription += ',';
                      }
                      this.slhiDescription += "  'Resolution Time (h)' changed from [" + this.slas[3].timeToConcluse + "] to [" + this.timeToConcluse4 + "] on priority 4";
                    }
      
                    if (this.slhiDescription != "") {
                      this.createSlaHistory('UPDATE', this.slas[3].id);
                    }
                  },
                  err => {
                    this.ttService.checkErr(err,'error update sla', this.modalRef);
                    this.alertsDismiss.push({
                      type: 'danger',
                      msg: err.errorerrorMessage,
                      timeout: 5000
                    });
                  },
                  () => resolve(true)
                );
            }else{
              resolve(true);
            }
          }).then(() => {
            resolve(true);
          })
        })
      })
    })
  }).then(() => {
    this.getSlasBySystem();
    alert('SLA is saved!');
  }
  )
}


// async updateSla() {  
//     try{   
//       let first = await this.doUpdate(1);
//       let second = await this.doUpdate(2);
//       let third = await this.doUpdate(3);
//       let fourth = await this.doUpdate(4);
//       let end = await this.refresh("last");
//     }catch(err){

//     }   
// }
// refresh(last){
//   if(last == "last"){
//     console.log("last")
//     this.getSlasBySystem()
//     return "end";
//   }
// }
// doUpdate(loopTime){
//   console.log("loop")
//   if(loopTime == 1){
//     if(this.timeToConcluse1 != this.slas[0].timeToConcluse || this.firstResponse1 != this.slas[0].firstResponse){
//       this.busy = this.ttService.updateSla(
//         {
//           'system'          : {'id' : this.systemId},
//           'prioritySla'     : {'id' : 1},
//           'firstResponse'   : this.firstResponse1,
//           'timeToConcluse'  : this.timeToConcluse1,
//           'activated'       : true
//         },this.slas[0].id).subscribe(
//           response => {
//             console.log("1")
//             this.slhiDescription = "";
  
//             if(this.firstResponse1 != this.slas[0].firstResponse){
//               this.slhiDescription += "  'Response Time (h)' changed from [" + this.slas[0].firstResponse + "] to [" + this.firstResponse1 + "] on priority 1";
//             }
        
//             if(this.timeToConcluse1 != this.slas[0].timeToConcluse){
//               if(this.slhiDescription.substr(-1) != ',' && this.slhiDescription != ''){
//                 this.slhiDescription += ',';
//               }
//               this.slhiDescription += "  'Resolution Time (h)' changed from [" + this.slas[0].timeToConcluse + "] to [" + this.timeToConcluse1 + "] on priority 1";
//             }
  
//             if (this.slhiDescription != "") {
//               this.createSlaHistory('UPDATE', this.slas[0].id);
//             }
            
//           },
//           err => {
//             this.ttService.checkErr(err,'error update sla', this.modalRef);
//             this.alertsDismiss.push({
//               type: 'danger',
//               msg: err.errorerrorMessage,
//               timeout: 5000
//             });
//           },
//           () => {
//             return 2;
//           }
//         );
//     }else{
//       return 2;
//     }
//   }

//   if(loopTime == 2){
//     console.log("loop 2")
//     if(this.timeToConcluse2 != this.slas[1].timeToConcluse || this.firstResponse2 != this.slas[1].firstResponse){
//       this.busy = this.ttService.updateSla(
//         {
//           'system'          : {'id' : this.systemId},
//           'prioritySla'     : {'id' : 2},
//           'firstResponse'   : this.firstResponse2,
//           'timeToConcluse'  : this.timeToConcluse2,
//           'activated'       : true
//         },this.slas[1].id).subscribe(
//           response => {
//             console.log(2);
//             this.slhiDescription = "";
  
//             if(this.firstResponse2 != this.slas[1].firstResponse){
//               this.slhiDescription += "  'Response Time (h)' changed from [" + this.slas[1].firstResponse + "] to [" + this.firstResponse2 + "] on priority 2";
//             }
        
//             if(this.timeToConcluse2 != this.slas[1].timeToConcluse){
//               if(this.slhiDescription.substr(-1) != ',' && this.slhiDescription != ''){
//                 this.slhiDescription += ',';
//               }
//               this.slhiDescription += "  'Resolution Time (h)' changed from [" + this.slas[1].timeToConcluse + "] to [" + this.timeToConcluse2 + "] on priority 2";
//             }
  
//             if (this.slhiDescription != "") {
//               this.createSlaHistory('UPDATE', this.slas[1].id);
//             }
//             return 3;
//           },
//           err => {
//             this.ttService.checkErr(err,'error update sla', this.modalRef);
//             this.alertsDismiss.push({
//               type: 'danger',
//               msg: err.errorerrorMessage,
//               timeout: 5000
//             });
//           }
//         );
//     }else{
//       return 3;
//     }
//   }
//   if(loopTime == 3){
//     if(this.timeToConcluse3 != this.slas[2].timeToConcluse || this.firstResponse3 != this.slas[2].firstResponse){
//       this.busy = this.ttService.updateSla(
//         {
//           'system'          : {'id' : this.systemId},
//           'prioritySla'     : {'id' : 3},
//           'firstResponse'   : this.firstResponse3,
//           'timeToConcluse'  : this.timeToConcluse3,
//           'activated'       : true
//         },this.slas[2].id).subscribe(
//           response => {
//             console.log("3")
//             this.slhiDescription = "";
  
//             if(this.firstResponse3 != this.slas[2].firstResponse){
//               this.slhiDescription += "  'Response Time (h)' changed from [" + this.slas[2].firstResponse + "] to [" + this.firstResponse3 + "] on priority 3";
//             }
  
//             if(this.timeToConcluse3 != this.slas[2].timeToConcluse){
//               if(this.slhiDescription.substr(-1) != ',' && this.slhiDescription != ''){
//                 this.slhiDescription += ',';
//               }
//               this.slhiDescription += "  'Resolution Time (h)' changed from [" + this.slas[2].timeToConcluse + "] to [" + this.timeToConcluse3 + "] on priority 3";
//             }
  
//             if (this.slhiDescription != "") {
//               this.createSlaHistory('UPDATE', this.slas[2].id);
//             }
//             return 4;
//           },
//           err => {
//             this.ttService.checkErr(err,'error update sla', this.modalRef);
//             this.alertsDismiss.push({
//               type: 'danger',
//               msg: err.errorerrorMessage,
//               timeout: 5000
//             });
//           }
//         );
//     }else{
//       return 4;
//     }
    
//   }
//   if(loopTime == 4){
//     if(this.timeToConcluse4 != this.slas[3].timeToConcluse || this.firstResponse4 != this.slas[3].firstResponse){
//       this.busy = this.ttService.updateSla(
//         {
//           'system'          : {'id' : this.systemId},
//           'prioritySla'     : {'id' : 4},
//           'firstResponse'   : this.firstResponse4,
//           'timeToConcluse'  : this.timeToConcluse4,
//           'activated'       : true
//         },this.slas[3].id).subscribe(
//           response => {
//             console.log("4")
//             this.slhiDescription = "";

//             if(this.firstResponse4 != this.slas[3].firstResponse){
//               this.slhiDescription += "  'Response Time (h)' changed from [" + this.slas[3].firstResponse + "] to [" + this.firstResponse4 + "] on priority 4";
//             }

//             if(this.timeToConcluse4 != this.slas[3].timeToConcluse){
//               if(this.slhiDescription.substr(-1) != ',' && this.slhiDescription != ''){
//                 this.slhiDescription += ',';
//               }
//               this.slhiDescription += "  'Resolution Time (h)' changed from [" + this.slas[3].timeToConcluse + "] to [" + this.timeToConcluse4 + "] on priority 4";
//             }

//             if (this.slhiDescription != "") {
//               this.createSlaHistory('UPDATE', this.slas[3].id);
//             }
//             return "last";
//           },
//           err => {
//             this.ttService.checkErr(err,'error update sla', this.modalRef);
//             this.alertsDismiss.push({
//               type: 'danger',
//               msg: err.errorerrorMessage,
//               timeout: 5000
//             });
//           }
//         );
//     }else{
//       return "last";
//     }
//   }
  

// }

createSlaHistory(action, slaId) {
  this.ttService.createSlaHistory({
    'action': action,
    'description': this.slhiDescription
  },slaId, sessionStorage.getItem('userId')).subscribe(
    response => {
      this.slhiDescription = '';
      this.paginate(null); 
    },
    err => {
      this.ttService.checkErr(err, 'error loading sla history', this.modalRef);
      this.alertsDismiss.push({
        type: 'danger',
        msg: err.error.errorMessage,
        timeout: 5000
      });
    },
  );
}

// openComment(template: TemplateRef<any>, task) {
//   this.newVersionModalRef = this.ngbModal.open(template);
//  }

openHistory(template: TemplateRef<any>, row) { 
  this.logTitle = row.id + " - " + row.action;
  this.logDescription = row.description.split(",");
  this.newVersionModalRef = this.ngbModal.open(template);
}
 
//  saveComment(){
//    this.createSlaHistory('OTHER');
//    this.newVersionModalRef.close('')
//    this.close();
//  }

paginate(event){
  this.event = event;
  let page;
    if(this.event){
      page = this.event.page;
    }else{
      page = 0;
    }
    let size = 10;
    
    this.getSlaHistory(page, size);
}

close() {
    this.modalRef.close('');
  }


}

