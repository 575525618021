import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-box-dialog',
  templateUrl: './confirm-box-dialog.component.html'
})
export class ConfirmBoxDialogComponent {

  constructor(private modal: NgbActiveModal) { }

  @Input() titleHeader: string;
  @Input() bodyMessage: string;

  @Input() buttons: [{
    type: string,
    className: string,
    text: string,
    action: Function 
  }];

  close(resolve: string) {
    this.modal.close(resolve);
  }
}
